import React,{useState,useEffect,useCallback} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import './CSS/island-background.css'
import './CSS/shop.css'
import Inventory from './Inventory'
import MapShip from './MapItems/MapShip'

import WalletItem from './MapItems/WalletItem'


const PirateMap = ({shipStats, pirateGold, inventory, cannons, inventory3, handleSelectedItem5,
inventory2, removeProductInv2,removeProductInv1, inventory4, removeProductInv,loot,handleSelectedCannon,
removeProductPotion, goldWallet, removeProductShipStat, inventory1, equip, setEquip, 
removeEquip, setRemoveEquip,inventory5,questBook,setQuestBook,questLog,
closeChat,setQuestBook1,questLog1, questBook1,questBook2,setQuestBook2,questLog2, closeChat2,
closeChat1,removeProductInv4,handleSelectedCannonX20,selectedOption, setSelectedOption,changeShipClass, setChangeShipClass,closeChat3,
changeShipPrompt, setChangePrompt, questLog3,sunkShip, sunkShip1,sunkShip2,sunkShip3,changeSink, setChangeSink,
changeSink1, setChangeSink1,changeSink2, setChangeSink2,changeSink3, setChangeSink3,deleteShip, setDeleteShip,deleteShip1, setDeleteShip1,
deleteShip2, setDeleteShip2,deleteShip3, setDeleteShip3,levelAnimation, setLevelAnimation,level, setLevel,
levelTwo, setLevelTwo,levelThree,setLevelThree,levelFour, setLevelFour,levelFive, setLevelFive}) => {
  
const navigate = useNavigate()
const [shipMessage, setShipMessage] = useState()
const [showBag, setShowBag] = useState(false)
const [logBook, setLogBook] = useState(false)

const [keyFoundThree, setKeyFoundThree] = useState(false)
const [keyFoundThree2, setKeyFoundThree2] = useState(false)
const [parrotFoundTwo, setParrotFoundTwo] = useState(false)
const [chaliceFoundThree, setChaliceFoundThree] = useState(false)
const [reward, setReward] = useState()
const [reward1, setReward1] = useState()
const [reward2, setReward2] = useState()
const [reward3, setReward3] = useState()





const walletItems = goldWallet.map((goldWallet, index)=>{
  return <WalletItem
    key={index}
    goldWallet={goldWallet}
    level={level}
    setLevel={setLevel}
    levelAnimation={levelAnimation}
    setLevelAnimation={setLevelAnimation}
    levelTwo={levelTwo}
    setLevelTwo={setLevelTwo}
    levelThree={levelThree}
    setLevelThree={setLevelThree}
    levelFour={levelFour}
    setLevelFour={setLevelFour}
    levelFive={levelFive}
    setLevelFive={setLevelFive}
  />})

const handleOptionChange = (e) => {
  setSelectedOption(e.target.value);
};

const handleSink =(reward, reward1)=>{
  setChangeSink('sink1')
  setReward(`${reward}XP `+ `$`+`${reward1}`)
  
  
  setTimeout(() => {
    setDeleteShip(false)
    setReward()
  },2000)

  const log =goldWallet.map((goldWallet, index)=>{
    let myXp =goldWallet.xp += reward
    let myGold =goldWallet.gold += reward1
    const myReward = myXp + myGold
   
    return <li key={index}>{myReward}</li>
})

}

  const handleSink1 =(reward, reward1)=>{
    setChangeSink1('sink1')
    setReward1(`${reward}XP `+ `$`+`${reward1}`)
  
    setTimeout(() => {
      setDeleteShip1(false)
      setReward1()
    },2000)

    const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  }

    const handleSink2 =(reward, reward1)=>{
      setChangeSink2('sink1')
      setReward2(`${reward}XP `+ `$`+`${reward1}`)
      setTimeout(() => {
        setDeleteShip2(false)
        setReward2()
      },2000)

      const log =goldWallet.map((goldWallet, index)=>{
        let myXp =goldWallet.xp += reward
        let myGold =goldWallet.gold += reward1
        const myReward = myXp + myGold
       
        return <li key={index}>{myReward}</li>
    })
    }
  const keyLoot = inventory5.filter(items => items.item === 'Gold Key');
  const parrotLoot = inventory5.filter(items => items.item === 'Parrot');
  const chaliceLoot = inventory5.filter(items => items.item === 'Chalice');
  
  useEffect(()=>{
    
    if(keyLoot.length === 3){
      setKeyFoundThree(true)
    }
    else if (parrotLoot.length === 2){
      setParrotFoundTwo(true)
    }
    else if (chaliceLoot.length === 3){
      setChaliceFoundThree(true)
    }
    if (keyLoot.length === 3){
      setKeyFoundThree2(true)
    }
  }, [keyFoundThree, parrotFoundTwo, chaliceFoundThree, setKeyFoundThree2])

      const handleSink3 =(reward, reward1)=>{

        setChangeSink3('sink1')
        setReward3(`${reward}XP `+ `$`+`${reward1}`)

        setTimeout(() => {
          setDeleteShip3(false)
          setReward3()
        },2000)

      const log =goldWallet.map((goldWallet, index)=>{
          let myXp =goldWallet.xp += reward
          let myGold =goldWallet.gold += reward1
          const myReward = myXp + myGold
         
          return <li key={index}>{myReward}</li>
      })
    } 

useEffect(() => {
  if (selectedOption === 'islandTwo') {
    setChangeShipClass('imgShipMap2');
  }
   else if (selectedOption === 'islandOne' ){
    setChangeShipClass('imgShipMap1');
  }
  else if (selectedOption === 'islandThree'){
    setChangeShipClass('imgShipMap3');
  }
  else if (selectedOption === 'islandFour'){
    setChangeShipClass('imgShipMap4');
  }
  else if (selectedOption === 'islandFive'){
    setChangeShipClass('imgShipMap5');
  }
  else if (selectedOption === 'islandSix'){
    setChangeShipClass('imgShipMap6');
  }
  else if (selectedOption === 'islandSeven'){
    setChangeShipClass('imgShipMap7');
  }

  else if (selectedOption === 'enemy1'){
    setChangeShipClass('imgShipenemy1');
  }
  else if (selectedOption === 'enemy2'){
    setChangeShipClass('imgShipenemy2');
  }
   if (selectedOption === 'enemy'){
    setChangeShipClass('imgShipenemy');
    setChangePrompt('ship-header1')
  }
  else{
    setChangePrompt('ship-header')
  }

  if (selectedOption === 'enemy3'){
    setChangeShipClass('imgShipenemy3');
    setChangePrompt('ship-header1')
  }
  
  else{

    setChangePrompt('ship-header')
  }

}, [selectedOption]);


const shipStatItem = shipStats.map((shipStats, index)=>{
  return <MapShip
    key={index}
    shipStats={shipStats}
  />})
  const shipMapIcon = shipStats.map((shipStats, index)=>{
    return <li className={changeShipClass}><h3 className="arrow">🔻</h3><img src={shipStats.img} alt="" width="80" height="80" /></li>
  })
const handleBrig =()=>{
  if(shipStats.length === 0){
    navigate('/island-map')
    setShipMessage(<h2 className='ship-prompt'>You Need A Ship To Battle Please Visit Shop</h2>)
    setTimeout(() => {
      setShipMessage()
    },1000)
   
  }
  else if (shipStats.length === 1 && selectedOption === 'enemy' && sunkShip === true){
    navigate('/enemy')
  }
}

const handleIsland1 =()=>{
  if (selectedOption === 'islandOne') {
    navigate("/island-one")
  }
else{
  navigate("/island-map")
}
}
const handleIsland2 =()=>{
  if (selectedOption === 'islandTwo') {
    navigate("/island-two")
  }
else{
  navigate("/island-map")
}
}
const handleIsland3 =()=>{
  if (selectedOption === 'islandThree') {
    navigate("/island-three")
  }
else{
  navigate("/island-map")
}
}
const handleIsland4 =()=>{
  if (selectedOption === 'islandFour') {
    navigate("/island-four")
  }
else{
  navigate("/island-map")
}
}
const handleIsland5 =()=>{
  if (selectedOption === 'islandFive') {
    navigate("/island-five")
  }
else{
  navigate("/island-map")
}
}
const handleIsland6 =()=>{
  if (selectedOption === 'islandSix') {
    navigate("/island-six")
  }
else{
  navigate("/island-map")
}
}
const handleIsland7 =()=>{
  if (selectedOption === 'islandSeven') {
    navigate("/island-seven")
  }
else{
  navigate("/island-map")
}

}


const handleBrig1 =()=>{
  if(shipStats.length === 0){
    navigate('/island-map')
    setShipMessage(<h2 className='ship-prompt'>You Need A Ship To Battle Please Visit Shop</h2>)
    setTimeout(() => {
      setShipMessage()
    },1000)
   
  }
  else if (shipStats.length === 1 && selectedOption === 'enemy3' && sunkShip3 === true){
    navigate('/enemy3')
  }
}

const handleSloop =()=>{
  if(shipStats.length === 0){
    navigate('/island-map')
    setShipMessage(<h2 className='ship-prompt'>You Need A Ship To Battle Please Visit Shop</h2>)
    setTimeout(() => {
      setShipMessage()
    },1000)
   
  }
  else if (shipStats.length === 1 && selectedOption === 'enemy1' && sunkShip1 === true){
    navigate('/enemy1')
  }
}
const handleGalleon =()=>{
  if(shipStats.length === 0){
    navigate('/island-map')
    setShipMessage(<h2 className='ship-prompt'>You Need A Ship To Battle Please Visit Shop</h2>)
    setTimeout(() => {
      setShipMessage()
    },1000)
   
  }
  else if (shipStats.length === 1 && selectedOption === 'enemy2' && sunkShip2 === true){
    navigate('/enemy2')
  }
}
const handleBag =()=>{
  setShowBag(!showBag)
  
}
const handleRemoveShip =()=>{
  removeProductShipStat(shipStats)
  setEquip(!equip)


}
const handleLogBook=()=>{
    setLogBook(!logBook)
}

if(questLog === true){
  setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
  
}
if(closeChat === true){
  setQuestBook("Quest Completed")
}
if(questLog1 === true){
  setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
}
if(closeChat1 === true){
  setQuestBook1("Quest Completed")
}

if(questLog2 === true){
  setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
}
if(closeChat2 === true){
  setQuestBook2("Quest Completed")
}




const currentPath = window.location.pathname;
  return (
    <>
   
  <div className='bag-icon1'>
    <div onClick={handleBag} >
    <img src={require("./img/bag.png")} alt='treasure chest' height='500' width='500'/>
    </div>
    </div>


    
    <div className='map-background'>
      <h3 className='shopGold'>{pirateGold}</h3>
      {walletItems}
      {/* <Link to="/shop">
      <h3 className='mapShopLogo'>Shop</h3>
      </Link> */}
    {shipMessage}
     {/* Island one */}
    <div className='island-map' onClick={handleIsland1}>
      
      <img src={require("./img/island-map.png")} alt=""
      height="200" width="200"/>
    
    </div>
  
    <p className='reward'>{reward}</p>
    <p className='reward1'>{reward1}</p>
    <p className='reward2'>{reward2}</p>
    <p className='reward3'>{reward3}</p>
<label className='IslandOneCheck'>
        <input
          type="radio"
          value="islandOne"
          checked={selectedOption === 'islandOne'}
          onChange={handleOptionChange}
        />
       
      </label>

    {/* Island two */}
    <div className='island-map1' onClick={handleIsland2} >
   
    <img src={require("./img/island-map1.png")} alt=""
      height="200" width="200"/>
   
    </div>
   
{equip && closeChat ? <label className='IslandTwoCheck'>
        <input
          type="radio"
          value="islandTwo"
          checked={selectedOption === 'islandTwo'}
          onChange={handleOptionChange}
        />
      
      </label>: null}
      {!closeChat ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockTwo'/>: null}

    {/* Island three */}
    <div className='island-map3' onClick={handleIsland3} >

    <img src={require("./img/island-map3.png")} alt=""
      height="200" width="200"/>

    </div>
   
{equip && closeChat3 ?    <label className='IslandThreeCheck'>
        <input
          type="radio"
          value="islandThree"
          checked={selectedOption === 'islandThree'}
          onChange={handleOptionChange}
        />
      
      </label>: null}
      {!closeChat3 ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockThree'/>:null}
   {/* Island four */}
    <div className='island-map2' onClick={handleIsland4}>
    
    <img src={require("./img/island-map2.png")} alt=""
      height="200" width="200"/>
   
    </div>
   
{ equip && closeChat3 ?   <label className='IslandFourCheck'>
        <input
          type="radio"
          value="islandFour"
          checked={selectedOption === 'islandFour'}
          onChange={handleOptionChange}
        />
      
      </label>:null}
      {!closeChat3 ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockFour'/>:null}

 {/* Island five */}
  
    <div className='island-map5' onClick={handleIsland5}>
   
    <img src={require("./img/cave-island.png")} alt=""
      height="200" width="200"/>
    
    </div>
    

{ equip && closeChat2 ?   <label className='IslandFiveCheck'>
        <input
          type="radio"
          value="islandFive"
          checked={selectedOption === 'islandFive'}
          onChange={handleOptionChange}
        />
        
      </label>:null}
      {!closeChat2 ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockFive'/>:null}

    {/* Island six */}
    <div className='island-map6' onClick={handleIsland6}>

    <img src={require("./img/three-palms.png")} alt=""
      height="200" width="200"/>
    
    </div>
  
{equip && closeChat2 ?   <label className='IslandSixCheck'>
        <input
          type="radio"
          value="islandSix"
          checked={selectedOption === 'islandSix'}
          onChange={handleOptionChange}
        />
        
      </label>: null}
      {!closeChat2 ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockSix'/>:null}

 {/* Island seven */}
    <div className='island-map7'onClick={handleIsland7} >
  
    <img src={require("./img/skull-island.png")} alt=""
      height="200" width="200"/>
  
    </div>
  
{ equip  && closeChat1?   <label className='IslandSevenCheck'>
        <input
          type="radio"
          value="islandSeven"
          checked={selectedOption === 'islandSeven'}
          onChange={handleOptionChange}
        />
    
      </label>: null}
      {!closeChat1 ?<img src={require("./img/padlock.png")} alt="" height="80" width="80" className='padlockSeven'/>:null}
   
    <div className='log-book-icon' onClick={handleLogBook}>
    <img src={require("./img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("./img/map.png")} alt="" height="80" width="80" /></Link></div>:null}



  {!sunkShip && changeSink === "sink" && parrotFoundTwo ?<button onClick={()=>handleSink(150,125)}className='button-sink'>Sink?</button>:null}
  {deleteShip ?<div className={changeSink}><div className='enemy-ship' onClick={handleBrig}>
  
  {sunkShip ?<img src={require("./img/brig.png")} alt="" height= '80' width='80'/>:<Link to='/ship-deck2'><img src={require("./img/fireBrigRevamp.png")} alt="" height= '80' width='80' className='brig-fire'/></Link>}
  </div>
  </div>:null}

{ questLog1 && shipStats.length === 1 && sunkShip ?<label className='enemy-check'>
        <input
          type="radio"
          value="enemy"
          checked={selectedOption === 'enemy'}
          onChange={handleOptionChange}
        />
       
      </label>: null}
  
  {!sunkShip3 && changeSink3 === "sink" && keyFoundThree2 ?<button onClick={()=>handleSink3(300,250)}className='button-sink3'>Sink?</button>:null}
  {deleteShip3?<div className={changeSink3}><div className='enemy-ship3' onClick={handleBrig1}>

  {sunkShip3?<img src={require("./img/brig.png")} alt="" height= '80' width='80'/>:<Link to='/ship-deck3'><img src={require("./img/fireBrigRevamp.png")} alt="" className='brig-fire' height= '80' width='80'/></Link>}
    </div>
    </div>:null}

  { questLog3 && shipStats.length === 1 && sunkShip3 ?<label className='enemy-check3'>
        <input
          type="radio"
          value="enemy3"
          checked={selectedOption === 'enemy3'}
          onChange={handleOptionChange}
        />
       
      </label>: null}
      {!sunkShip1 && changeSink1 === "sink" && chaliceFoundThree?<button onClick={()=>handleSink1(100,200)}className='button-sink2'>Sink?</button>:null}
      {deleteShip1?<div className={changeSink1}><div className='enemy-ship1' onClick={handleSloop}>
      
    {sunkShip1 ?<img src={require("./img/sloop.png")} alt="" height= '80' width='80'/>:<Link to='/ship-deck1'><img src={require("./img/fireSloopRevamp.png")} alt="" height= '80' width='80'/></Link>}
   
    
    </div>
    </div>:null}

    { questLog2 && shipStats.length === 1 && sunkShip1?<label className='enemy-check1'>
        <input
          type="radio"
          value="enemy1"
          checked={selectedOption === 'enemy1'}
          onChange={handleOptionChange}
        />
       
      </label>: null}

      {!sunkShip2 && changeSink2 === "sink" && keyFoundThree ?<button onClick={()=>handleSink2(200,100)}className='button-sink1'>Sink?</button>:null}
      {deleteShip2 ?<div className={changeSink2}><div className='enemy-ship2' onClick={handleGalleon}>
    
    {sunkShip2 ?<img src={require("./img/galleonRevamp.png")} alt="" height= '80' width='80'/>:<Link to='/ship-deck'><img src={require("./img/galleonFireRevamp.png")} alt="" height= '80' width='80'/></Link>}
    
    </div></div>:null}

    { questLog && shipStats.length === 1 && sunkShip2 ?<label className='enemy-check2'>
        <input
          type="radio"
          value="enemy2"
          checked={selectedOption === 'enemy2'}
          onChange={handleOptionChange}
        />
       
      </label>: null}

    {showBag? <div className='inventory-island' >
    <Inventory currentPath={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip={setEquip} equip={equip} removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}
        level={level} setLevel={setLevel}
       /></div>:null}
    {  equip?<p className='ship-item-map'>
<h4>
Cannonballs no: {cannons.length}<br></br>
Planks no: {inventory3.length}<br></br>

<button onClick={handleRemoveShip}>Remove Ship</button>
</h4>
   {shipStatItem}
   
    
    </p>: null}
    {shipMapIcon}


    </div>
    </>
  )
}

export default PirateMap