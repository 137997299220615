import './App.css';
import PirateContainer from './containers/PirateContainer';




function App() {

 

  return (
    <div className="App">
      <PirateContainer />
  
   
    </div>
  );
}


export default App;
