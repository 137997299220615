import React from 'react'
import LootItem from '../MapItems/LootItem'
import '../CSS/shop.css'
import LootItemPotions from '../MapItems/LootItemPotions'

const LootListC1 = ({loot, handleSelectedItem2, removeProductLoot, setShowLoot,potion, handleSelectedItemLootPotion,
    removePotionLoot}) => {
    const selectedLoot = loot.filter(items => items.chest.endsWith("1"));
 

    const lootItems = selectedLoot.map((loot) => {
       return <LootItem 
        key={loot._id}
        loot={loot}
        handleSelectedItem2={handleSelectedItem2}
        removeProductLoot={removeProductLoot}
       /> 
    })


    const selectedPotions = potion.filter(items => items.chest.endsWith("7"))
    const potionItems = selectedPotions.map((potion) => {
      return <LootItemPotions 
       key={potion._id}
       potion={potion}
       handleSelectedItemLootPotion={handleSelectedItemLootPotion}
       removePotionLoot={removePotionLoot}
      /> 
   })
   










  return (
  <>

    <div className='loot-table'>
        {lootItems}
        
   
        {potionItems}
        <button onClick={()=>setShowLoot(false)}className="close-button">Close</button>
        
    </div>


  


 </>
 
  )
}

export default LootListC1