import React from 'react'

const CannonballItem = ({cannonball, handleSelectedCannonball}) => {

    const handleBuy =(e)=>{
        handleSelectedCannonball(e.target.value)
    }
  return (
    <div className='game-item'>
      <img src={cannonball.img} width="60"height="60"alt=""/>x{cannonball.quantity}<br/>${cannonball.price}<br/><button className='cannon-button' onClick={handleBuy} value={cannonball._id}>Buy</button>
      
    </div>
  )
}

export default CannonballItem