import React from 'react'
import './CSS/shop.css'
import CartItem from './MapItems/CartItem'
import CartItem1 from './MapItems/CartItem1'
import CartItem2 from './MapItems/CartItem2'
import CartItem3 from './MapItems/CartItem3'

const Cart = ({cart, removeProductCart, handleSelectedItem1, goldWallet, setGoldWallet,
pirateGold, buyGold, setBuyGold, cart1, removeProductCart1, handleSelectedItem4,
cart2, handleSelectedPotionInv, removeProductCart2, cart3,handleSelectedInv4,removeProductCart3,
setPurchase,inventory4
}) => {

    const cartItems = cart.map((cart, index)=>{
        return <CartItem key={index}
                         cart={cart}
                         removeProductCart={removeProductCart}
                         handleSelectedItem1={handleSelectedItem1}
                         goldWallet={goldWallet}
                         setGoldWallet={setGoldWallet}
                         pirateGold={pirateGold}
                         buyGold={buyGold}
                         setBuyGold={setBuyGold}
                         setPurchase={setPurchase}
                         />
    })

    const cartItems1 = cart1.map((cart1, index)=>{
            return <CartItem1 
                key={index}
                cart1={cart1}
                removeProductCart1={removeProductCart1}
                handleSelectedItem4={handleSelectedItem4}
                goldWallet={goldWallet}
                setPurchase={setPurchase}
            />
    })

    const cartItems2 = cart2.map((cart2, index)=>{
      return <CartItem2 
          key={index}
          cart2={cart2}
          removeProductCart1={removeProductCart1}
          handleSelectedItem4={handleSelectedItem4}
          goldWallet={goldWallet}
          handleSelectedPotionInv={handleSelectedPotionInv}
          removeProductCart2={removeProductCart2}
          setPurchase={setPurchase}
          
      />
})
const cartItem3 = cart3.map((cart3, index)=>{
  return <CartItem3 
        key={index}
        cart3={cart3}
        handleSelectedInv4={handleSelectedInv4}
        removeProductCart3={removeProductCart3}
        goldWallet={goldWallet}
        setPurchase={setPurchase}
        inventory4={inventory4}
  
  />
})

let cartTotal = cart1.reduce((total, cart) =>{
  return (total += cart.price)
}, 0);
let cartTotal1 = cart2.reduce((total, cart) =>{
  return (total += cart.price)
}, 0);
let cartTotal2 = cart3.reduce((total, cart) =>{
  return (total += cart.price)
}, 0);

const allCarts = cartTotal += cartTotal1 += cartTotal2;
// const handleBuyAll=()=>{
//   let result
//  goldWallet.map((goldWallet, index)=>{

//   if(goldWallet.gold > 0){
//     result = goldWallet.gold -= allCarts
//     setGoldWallet(result)
//   }

//               })
//     }
   
  return (
    <div className='cart-items'>
        {cartItems}
        {cartItems1}
        {cartItems2}
        {cartItem3}
        <p className='total'>Total: {allCarts}</p>
        {/* <button onClick={handleBuyAll}>Buy All</button> */}
        
      
        
        
        
    </div>
  )
}

export default Cart