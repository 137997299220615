import React,{useState} from 'react'
import './CSS/shop.css'
import InventoryItem from './MapItems/InventoryItem'
import InventoryItem1 from './MapItems/InventoryItem1'
import InventoryItem2 from './MapItems/InventoryItem2'
import InventoryItem4 from './MapItems/InventoryItem4'
import InventoryItem3 from './MapItems/inventoryItem3'
import InventoryItem5 from './MapItems/InventoryItem5'






const Inventory = ({inventory, removeProductInv, goldWallet, loot, inventory1, removeProductInv1,
setShowBag,inventory2, removeProductInv2, setEquip, equip, handleSelectedItem5, removeEquip,
removeProductShipStat, loading,  ship, inventory3, shipStats, removeProductPotion,
inventory4,removeProductInv4, handleSelectedCannon, 
 currentPath, inventory5,handleQuestHolder,removeQuestInv,handleQuestHolder1,
 questLog, questLog1,closeChat,closeChat1,closeChat2,questLog2,handleQuestHolder2,showHeal, setShowHeal,handleSelectedCannonX20,
 questLog3,setQuestLog3,handleQuestHolder3, handleFragmentHolder,questFragment,
 level,setLevel
 

}) => {


 


  
    const inventoryItems =  inventory.map((bag, index)=>{
        return <InventoryItem 
        key={index}
        bag={bag}
        removeProductInv={removeProductInv}
        goldWallet={goldWallet}
        loot={loot}
      
        ship={ship}
        
        />
    })
    const inventoryItems1 =  inventory1.map((bag, index)=>{
        return <InventoryItem1
        key={index}
        bag={bag}
        removeProductInv1={removeProductInv1}
        goldWallet={goldWallet}
        loot={loot}
        currentPath={currentPath}
      
      
        
        />
    })

    const inventoryItems2 =  inventory2.map((bag, index)=>{
        return <InventoryItem2
        key={index}
        bag={bag}
        goldWallet={goldWallet}
        removeProductInv2={removeProductInv2}
        setEquip={setEquip}
        equip={equip}
        handleSelectedItem5={handleSelectedItem5}
        removeEquip={removeEquip}
        removeProductShipStat={removeProductShipStat}
        loading={loading}
        currentPath={currentPath}
      />
    })

    const inventoryItems3 =  inventory3.map((bag, index)=>{
      return <InventoryItem3
      key={index}
      bag={bag}
      shipStats={shipStats}
      removeProductShipStat={removeProductShipStat}
      removeProductPotion={removeProductPotion}
      goldWallet={goldWallet}
      currentPath={currentPath}
      showHeal={showHeal}
      setShowHeal={setShowHeal}
      />
  })
 const inventoryItems4 = inventory4.map((bag, index)=>{
     return <InventoryItem4 
      key={index}
      bag={bag}
      handleSelectedCannon={handleSelectedCannon}
      goldWallet={goldWallet}
      removeProductInv4={removeProductInv4}
      currentPath={currentPath}
      handleSelectedCannonX20={handleSelectedCannonX20}
      
    />
 })

 const inventoryItems5 = inventory5.map((bag, index)=>{
  return <InventoryItem5 
   key={index}
   bag={bag}
   handleQuestHolder={handleQuestHolder}
   removeQuestInv={removeQuestInv}
   handleQuestHolder1={handleQuestHolder1}
   currentPath={currentPath}
   questLog={questLog}
   questLog1={questLog1}
   closeChat={closeChat}
   closeChat1={closeChat1}
   closeChat2={closeChat2}
   questLog2={questLog2}
   handleQuestHolder2={handleQuestHolder2}
   handleQuestHolder3={handleQuestHolder3}
   questLog3={questLog3}
    setQuestLog3={setQuestLog3}
    handleFragmentHolder={handleFragmentHolder}
    questFragment={questFragment}
 />
})

const [isOpen, setIsOpen] = useState(false);
const [selectedItem, setSelectedItem] = useState(null);

const handleToggle = () => {
  setIsOpen(!isOpen);
};

const handleSelect = (option) => {
  setSelectedItem(option.ship)
  setIsOpen(false);
  handleSelectedItem5(option._id)
  setEquip(true)
};

 




   
  return (
    <>
  

    <div>
     
    <div className="dropdown">
      <button className="dropdown-toggle" onClick={handleToggle}>
        {selectedItem || 'Choose Ship'}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {inventory2.map((option) => (
            <li key={option._id} onClick={() => handleSelect(option)} className='ship-hover' >
              {option.ship}
            </li>
          ))}
        </ul>
      )}
    </div>


<div >
  {inventoryItems}
  </div>

  <div >
  {inventoryItems1}
  </div>
  <div >
  {inventoryItems2}
  </div>
  <div>
  {inventoryItems3}
  </div>
  <div>
  {inventoryItems4}
  </div>
  <div>
  {inventoryItems5}
  </div>
  

  { currentPath === "/island-map" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/island-one" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/island-two" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/island-three" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/island-four" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
        { currentPath === "/island-five" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/ship-deck" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/ship-deck1" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
        { currentPath === "/ship-deck2" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
        { currentPath === "/ship-deck3" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}
    { currentPath === "/my-ship" ?
    (<button onClick={()=>setShowBag(false)} className="inv-close">close</button>)
    :null}




    </div>
    </>
  )
}

export default Inventory