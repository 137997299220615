import React, {useState} from 'react'
import LootList3C1 from '../LootLists/LootList3C1'
import LootList3C2 from '../LootLists/LootList3C2'
import Inventory from '../Inventory'
import '../CSS/island-background.css'
import ShipStatItem from '../MapItems/ShipStatItem'
import { Route, Routes, Link } from 'react-router-dom'
import WalletItem from '../MapItems/WalletItem'

const IslandFour = ({loot, handleSelectedItem2, inventory, removeProductInv, shipStats,
goldWallet, inventory1, removeProductInv1, removeProductLoot, removeProductInv2, inventory2, handleSelectedItem5,
removeProductShipStat, potion,  inventory3, removeProductPotion, handleSelectedItemLootPotion, ship,
handleSelectedCannon, cannons, inventory4, setEquip, equip,  removePotionLoot, hideChest3,
pirateGold,inventory5,closeChat1,setCloseChat1, setQuestBook1,questBook1,closeChat,setCloseChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2,removeProductInv4,handleSelectedCannonX20,
setSelectedOption,levelAnimation,setLevelAnimation,level,setLevel,levelTwo,setLevelTwo,levelThree,setLevelThree,levelFour, setLevelFour,
levelFive, setLevelFive
}) => {
    const [showLoot, setShowLoot] = useState(false)
    const [showLoot1, setShowLoot1] = useState(false)
    const [showBag, setShowBag] = useState(false)
    const [logBook, setLogBook] = useState(false)
    const [removeEquip, setRemoveEquip]=useState()
    
    const walletItems = goldWallet.map((goldWallet, index)=>{
      return <WalletItem
        key={index}
        goldWallet={goldWallet}
        level={level}
        setLevel={setLevel}
        levelAnimation={levelAnimation}
        setLevelAnimation={setLevelAnimation}
        levelTwo={levelTwo}
        setLevelTwo={setLevelTwo}
        levelThree={levelThree}
        setLevelThree={setLevelThree}
        levelFour={levelFour}
        setLevelFour={setLevelFour}
        levelFive={levelFive}
        setLevelFive={setLevelFive}
      />})
    
    const shipStatItems = shipStats.map((shipStats, index)=>{
      return <ShipStatItem
          key={index}
          shipStats={shipStats}
          removeProductShipStat={removeProductShipStat}
          equip={equip}
          setEquip={setEquip}
          setRemoveEquip={setRemoveEquip}
          removeEquip={removeEquip}
          potion={potion}
          inventory3={inventory3}
          ship={ship}
          

       
          
          />
    })
    const handleBag =()=>{
      setShowBag(!showBag)
      
    }
const handleRemoveShip =()=>{
    setEquip(!equip)
    removeProductShipStat(shipStats)
    setSelectedOption("islandFour")
}
    
const handleLogBook=()=>{
  setLogBook(!logBook)
}
if(questLog === true){
  setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
  
}
if(closeChat === true){
  setQuestBook("Quest Completed")
}


if(questLog1 === true){
  setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
}
if(closeChat1 === true){
  setQuestBook1("Quest Completed")
}

if(questLog2 === true){
  setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
}
if(closeChat2 === true){
  setQuestBook2("Quest Completed")
}
const currentPath = window.location.pathname;
  return (
    <div className='island3' >
      <h3 className='shopGold'>{pirateGold}</h3>
      <div className='log-book-icon1' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}


<div >
  
{hideChest3?<div onClick={()=>setShowLoot(!showLoot)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI7'/>
        {showLoot? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI7'/> }
    </div>:null}
{hideChest3? <div onClick={()=>setShowLoot1(!showLoot1)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI8'/>
        {showLoot1? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI8'/> }
    </div>:null}
    </div>



    <div className='loot-oneC4'>
     {showLoot? <LootList3C1 loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}/> :null}
    </div>
    <div className='loot-twoC4'>
     {showLoot1? <LootList3C2 loot={loot}setShowLoot1={setShowLoot1} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}/> :null}
    </div>
    

 {  equip?<div className='ship-item'>
  
 <h4>
  <p>Cannonballs no: {cannons.length}</p>
   <p>potion no. {inventory3.length}</p>
   <button onClick={handleRemoveShip}>Remove Ship</button>
   </h4>
  
    <div className='ship-island'>{shipStatItems}</div>
    
  
   

    </div>:null}
   { showBag? <div className='inventory-island' >
   <Inventory currentPath={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip={setEquip} equip={equip} removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}
       />
    </div> :null}
    <Link to="/shop" >
    <div className='sign-post'>
      <img src={require('../img/signpost.png')} alt="" height='400' width="200"/>
    </div>
    </Link>
    {walletItems}
    <div className='bag-icon' >
        <div onClick={handleBag}>
        <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'/>
    </div>

    <Link to="/island-map" >
    <div className='map-icon'>
    <img src={require("../img/map.png")} alt='' height='300' width='300'
        />
    </div>
    </Link>


    </div>

    </div>
  )
}

export default IslandFour