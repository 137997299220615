import React, {useState} from 'react'

const CartItem3 = ({cart3, handleSelectedInv4,removeProductCart3, goldWallet, setPurchase}) => {

const handleBuy =(e)=>{
    handleSelectedInv4(e.target.value)
    
    removeProductCart3(cart3)

    const goldItems = goldWallet.map((goldWallet, index)=>{
        return <li key={index}>{goldWallet.gold -= cart3.price}</li>
                    })
                    setPurchase(<p className='shop-purchase'>Thanks for you're purchase!</p>)
                    setTimeout(()=>{
                      setPurchase()
                      }, 2000)
                    
}

const handleRemove=(()=>{
    removeProductCart3(cart3)
})

  return (
    <div className='game-item'>
       <p><img src={cart3.img} alt="" height="40" width="40"/>x{cart3.quantity}<br/>${cart3.price}<br/><button onClick={handleBuy} value={cart3._id}>Buy</button><button onClick={handleRemove}>Remove</button></p>
     
        
    </div>
  )
}

export default CartItem3