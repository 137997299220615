import React from 'react'

const InventoryItem5 = ({bag,handleQuestHolder,removeQuestInv,handleQuestHolder1,currentPath, questLog,
questLog1,closeChat,closeChat1,closeChat2,questLog2,handleQuestHolder2, questLog3, setQuestLog3, handleQuestHolder3,
handleFragmentHolder,questFragment}) => {

const handleQuest =(e)=>
        {
          
          handleQuestHolder(e.target.value)
          removeQuestInv(bag)
        }

        const handleQuest1 =(e)=>
        {
        //this one for island 2
          handleQuestHolder1(e.target.value)
          removeQuestInv(bag)
        }
        const handleQuest2 =(e)=>
        {
        
          handleQuestHolder2(e.target.value)
          removeQuestInv(bag)
        }
        const handleQuest3 =(e)=>
        {
        
          handleQuestHolder3(e.target.value)
          removeQuestInv(bag)
        }

        // const handleQuest4 =(e)=>
        // {
        
        //   handleQuestHolder3(e.target.value)
        //   removeQuestInv(bag)
        // }

        const handleQuest4 =(e)=>
        {
          handleFragmentHolder(e.target.value)
          removeQuestInv(bag)
        }

        
  return (
  
        
        <div className='game-item'>
        <p>
            <img src={bag.img} alt="" height="50" width="50"/><br/>
            
            

        { currentPath === "/island-one" && questLog ?
          (!closeChat?<button onClick={handleQuest} value={bag._id}className="load-button">Hand In</button>:null)
        :null}

      
        { currentPath === "/island-two" && questLog1?
          (!closeChat1?<button onClick={handleQuest1} value={bag._id}className="load-button">Hand In</button>:null)
        :null}
        <br/>
        { currentPath === "/island-seven" && questLog2?
          (!closeChat2?<button onClick={handleQuest2} value={bag._id}className="load-button">Hand In</button>:null)
        :null}

{ currentPath === "/island-five" && questLog3?
          (!closeChat2?<button onClick={handleQuest3} value={bag._id}className="load-button">Hand In</button>:null)
        :null}
{ currentPath === "/my-ship" && questFragment?
          (!closeChat2?<button onClick={handleQuest4} value={bag._id}className="load-button">Hand In</button>:null)
        :null}
  
    </p>
        
        
    </div>
 
  )
}

export default InventoryItem5