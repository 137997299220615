import React from 'react'

const InventoryItem1 = ({bag, removeProductInv1, goldWallet, renderButton, currentPath,
xpGain}) => {

  

    const handleSell =()=>{
        removeProductInv1(bag)
        const sellItems = goldWallet.map((goldWallet, index)=>{
            return <li key={index}>{goldWallet.gold += bag.price}</li>
                        })
    }

    
  return (
    <div className='game-item1'>
        <p><img src={bag.img} alt="" height="40" width="40"/>
        { currentPath === "/shop" ?
          (<button onClick={handleSell}className="load-button">Sell</button>)
        :null}<br/>
        </p>

    </div>
  )
}

export default InventoryItem1