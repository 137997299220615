import React, {useState} from 'react'

const CartItem = ({cart, removeProductCart, handleSelectedItem1,goldWallet, setGoldWallet,
pirateGold, buyGold, setBuyGold}) => {
    
    const handleDelete =()=>{
        removeProductCart(cart)
    }
    const handleBuyItem = (e) =>{
      
        handleSelectedItem1(e.target.value)
        removeProductCart(cart)

        const goldItems = goldWallet.map((goldWallet, index)=>{
            return <li key={index}>{goldWallet.gold -= cart.price}</li>
                        })
    }

   // let result;
        // let cartPrice = cart.price

        // if(cartPrice > 0){
        // result = pirateGold -= cartPrice
        // } 

  return (
    <div className='game-item'>
        <p>{cart.weapon}{cart.potion}<button onClick={handleDelete}>Remove</button><button onClick={handleBuyItem} value={cart._id}>Buy</button></p>
     
        
    </div>
  )
}

export default CartItem