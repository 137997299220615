import React, {useState, useEffect,  useCallback}from 'react'
import PirateService from '../services/PirateService'
import { Routes, Route} from 'react-router-dom';
import Shop from '../components/Shop';
import Home from '../components/Home';
import IslandOne from '../components/Islands/IslandOne';
import IslandTwo from '../components/Islands/IslandTwo';
import IslandThree from'../components/Islands/IslandThree'
import IslandFour from '../components/Islands/IslandFour'
import PirateMap from '../components/PirateMap';
import EnemyBattle from '../components/Enemies/EnemyBattle';
import EnemyBattle1 from '../components/Enemies/EnemyBattle1';
import EnemyBattle2 from '../components/Enemies/EnemyBattle2';
import EnemyBattle3 from '../components/Enemies/EnemyBattle3';
import ShipDeck from '../components/ShipDecks/ShipDeck';
import ShipDeck1 from '../components/ShipDecks/ShipDeck1';
import ShipDeck2 from '../components/ShipDecks/ShipDeck2';
import ShipDeck3 from '../components/ShipDecks/ShipDeck3';
import MyShipDeck from '../components/ShipDecks/MyShipDeck';
import IslandFive from '../components/Islands/IslandFive';
import IslandSix from '../components/Islands/IslandSix';
import IslandSeven from '../components/Islands/IslandSeven';
import Defeat from '../components/Defeat';
import ExtraArea3 from '../components/Islands/ExtraArea3';
import ExtraArea1 from '../components/Islands/ExtraArea1';
import ExtraArea5 from '../components/Islands/ExtraArea5';






const PirateContainer = () => {
const [goldWallet, setGoldWallet] = useState ([])
const [shopItems, setShopItems] = useState ([])
const [questItems, setQuestItems] = useState ([])
const [xpPoints, setXpPoints] = useState ([])
const [loot, setLoot] = useState([])
const [cart, setCart] = useState([])
const [cart1, setCart1] = useState([])
const [cart2, setCart2] = useState([])
const [cart3, setCart3] = useState([])
const [inventory, setInventory] = useState([])
const [inventory1, setInventory1] = useState([])
const [inventory2, setInventory2] = useState([])
const [inventory3, setInventory3] = useState([])
const [inventory4, setInventory4] = useState([])
const [inventory5, setInventory5] = useState([])
const [ship, setShip] = useState([])
const [shipStats, setShipStats] = useState([])
const [potion, setPotion] = useState([])
const [cannonball, setCannonball] = useState([])
const [cannons, setCannons] = useState([])
const [enemyShip, setEnemyShip] = useState([])
const [equip,setEquip] = useState(false)
const [equip1,setEquip1] = useState(false)
const [equip2,setEquip2] = useState(false)
const [hideChest, setHideChest] = useState(false)
const [hideChest1, setHideChest1] = useState(false)
const [hideChest2, setHideChest2] = useState(false)
const [hideChest3, setHideChest3] = useState(false)
const [revealPrompt, setRevealPrompt] = useState()
const [revealPrompt1, setRevealPrompt1] = useState()
const [revealPrompt2, setRevealPrompt2] = useState()
const [revealPrompt3, setRevealPrompt3] = useState()
const [revealPrompt4, setRevealPrompt4] = useState()
const [board, setBoard] = useState(false)
const [board1, setBoard1] = useState(false)
const [board2, setBoard2] = useState(false)
const [board3, setBoard3] = useState(false)
const [removeEquip, setRemoveEquip]=useState()
const [removeEquip1, setRemoveEquip1]=useState()
const [removeEquip2, setRemoveEquip2]=useState()
const [shopMenu, setShopMenu]=useState(false)
const [button, setButton]=useState(false)
const [animateShip, setAnimateShip] = useState('ship-position1')
const [animateEnemy, setAnimateEnemy] = useState('brig-enemy')
const [questHolder, setQuestHolder] = useState([])
const [questHolder1, setQuestHolder1] = useState([])
const [questHolder2, setQuestHolder2] = useState([])
const [questHolder3, setQuestHolder3] = useState([])
const [questHolder4, setQuestHolder4] = useState([])
const [questLog, setQuestLog] = useState(false)
const [questLog1, setQuestLog1] = useState(false)
const [questLog2, setQuestLog2] = useState(false)
const [closeChat, setCloseChat]=useState(false)
const [closeChat1, setCloseChat1]=useState(false)
const [closeChat2, setCloseChat2]=useState(false)
const [closeChat3, setCloseChat3]=useState(false)
const [closeChat4, setCloseChat4]=useState(true)
const [timeOutChat3, setTimeoutChat3] = useState(true)
const [questBook3, setQuestBook3] = useState(false)
const [questLog3, setQuestLog3] = useState(false)
const [timeOutChat, setTimeoutChat] = useState(true)
const [timeOutChat1, setTimeoutChat1] = useState(true)
const [timeOutChat2, setTimeoutChat2] = useState(true)
const [timeOutChat4, setTimeoutChat4] = useState(true)
const [timeOutChat5, setTimeoutChat5] = useState(true)
const [timeOutChat6, setTimeoutChat6] = useState(true)
const [questBook, setQuestBook] = useState(false)
const [questBook1, setQuestBook1] = useState(false)
const [questBook2, setQuestBook2] = useState(false)
const [selectedOption, setSelectedOption] = useState('islandOne');
const [changeShipClass, setChangeShipClass] = useState('imgShipMap1');
const [recruitDialogue, setRecruitDialogue] = useState(false)
const [showRecruit, setShowRecruit] = useState(false);
const [recruitDialogue1, setRecruitDialogue1] = useState(false)
const [showRecruit1, setShowRecruit1] = useState(true);
const [recruitIsland5, setRecruitIsland5] = useState(true)
const [showRecruit2, setShowRecruit2] = useState(true);
const [recruitDialogue2, setRecruitDialogue2] = useState(false)
const [showRecruit3, setShowRecruit3] = useState(true);
const [recruitDialogue3, setRecruitDialogue3] = useState(false)
const [myShip, setMyShip] = useState(false)
const [myShip1, setMyShip1] = useState(false)
const [myShip2, setMyShip2] = useState(false)
const [myShip3, setMyShip3] = useState(false)
const [deckTalk, setDeckTalk] = useState(true)
const [deckTalk1, setDeckTalk1] = useState(false)
const [deckTalk2, setDeckTalk2] = useState(true)
const [deckTalk3, setDeckTalk3] = useState(false)
const [deckTalk4, setDeckTalk4] = useState(true)
const [deckTalk5, setDeckTalk5] = useState(false)
const [deckTalk6, setDeckTalk6] = useState(true)
const [deckTalk7, setDeckTalk7] = useState(false)
const [mapQuest, setMapQuest] = useState(false)
const [mapQuest1, setMapQuest1] = useState(false)
const [nextQuest, setNextQuest] = useState(false)
const [nextQuest1, setNextQuest1] = useState(false)
const [nextQuest2, setNextQuest2] = useState(false) 
const [mapPiece, setMapPiece] = useState(false)
const [fragmentHolder, setMapFragmentHolder] = useState([])
const [initiateChat, setInitiateChat] = useState(false)
const [startPrompt, setStartPrompt] = useState(false)
const [questFragment, setQuestFragment] = useState(false)
const [hide, setHide] =useState(true)
const [hasRunOnce, setHasRunOnce] = useState(false);
const [hasRunOnce1, setHasRunOnce1] = useState(false);
const [hasRunOnce2, setHasRunOnce2] = useState(false);
const [checkInv6, setCheckInv6]= useState(false)
const [changeShipPrompt, setChangePrompt] = useState('ship-header')
const [sunkShip, setSunkShip] = useState(true)
const [sunkShip1, setSunkShip1] = useState(true)
const [sunkShip2, setSunkShip2] = useState(true)
const [sunkShip3, setSunkShip3] = useState(true)
const [changeSink, setChangeSink] = useState('sink')
const [changeSink1, setChangeSink1] = useState('sink')
const [changeSink2, setChangeSink2] = useState('sink')
const [changeSink3, setChangeSink3] = useState('sink')
const [deleteShip, setDeleteShip] = useState(true);
const [deleteShip1, setDeleteShip1] = useState(true);
const [deleteShip2, setDeleteShip2] = useState(true);
const [deleteShip3, setDeleteShip3] = useState(true);
const [unlock, setUnlock] = useState(false);
const [unlock1, setUnlock1] = useState(false);
const [unlock2, setUnlock2] = useState(false);
const [unlock3, setUnlock3] = useState(false);
const [waitUnlock, setWaitUnlock] = useState(false)
const [waitUnlock1, setWaitUnlock1] = useState(false)
const [waitUnlock2, setWaitUnlock2] = useState(false)
const [waitUnlock3, setWaitUnlock3] = useState(false)
const [sinkPrompt, setSinkPrompt] = useState()
const [level, setLevel] = useState('');
const [levelAnimation, setLevelAnimation] = useState('level');
const [levelTwo, setLevelTwo] = useState(false);
const [levelThree, setLevelThree] = useState(false);
const [levelFour, setLevelFour] = useState(false);
const [levelFive, setLevelFive] = useState(false);




const pirateGold = goldWallet.map((goldWallet, index) => {
  let displayedLevel = goldWallet.level;
  let remainingXp = 0;
  
  if (goldWallet.xp >= 400 && goldWallet.level < 2) {
    displayedLevel = 2;
    remainingXp = goldWallet.xp - 400;
    goldWallet.level = displayedLevel;
    goldWallet.xp = remainingXp;
  } else if (goldWallet.xp >= 500 && goldWallet.level < 3) {
    displayedLevel = 3;
    remainingXp = goldWallet.xp - 500;
    goldWallet.level = displayedLevel;
    goldWallet.xp = remainingXp;
  } else if (goldWallet.xp >= 600 && goldWallet.level < 4) {
    displayedLevel = 4;
    remainingXp = goldWallet.xp - 600;
    goldWallet.level = displayedLevel;
    goldWallet.xp = remainingXp;
  } else if (goldWallet.xp >= 700 && goldWallet.level < 5) {
    displayedLevel = 5;
    remainingXp = goldWallet.xp - 700;
    goldWallet.level = displayedLevel;
    goldWallet.xp = remainingXp;
  }

  return (
    <li key={index}>
      <img src={require('../components/img/money.png')} width='70' height='70' alt='' className='img-money' />
      <h4 className='h3-money'>
        {goldWallet.gold} / XP: {goldWallet.xp} / Level: {displayedLevel}
      </h4>
    </li>
  );
});













   useEffect(()=>{
    PirateService.findGold().then(gold => setGoldWallet(gold))
    PirateService.findShopItems().then(items => setShopItems(items))
    PirateService.findLootItems().then(loot => setLoot(loot))
    PirateService.findShip().then(ship => setShip(ship)) 
    PirateService.findPotion().then(potion => setPotion(potion)) 
    PirateService.findCannonball().then(cannonball => setCannonball(cannonball))
    PirateService.findEnemyShip().then(enemy => setEnemyShip(enemy))
    PirateService.findQuestItems().then(quest => setQuestItems(quest))
    PirateService.findXpPoints().then(xp => setXpPoints(xp))

    
   }, [])




   let selectedItem;
   const handleSelectedItem = (shopId) => {
       selectedItem = shopItems.find((shop) => shop._id === (shopId))
       setCart([...cart, selectedItem])
   }

   const handleSelectedItem1 = (shopId) => {
    selectedItem = cart.find((shop) => shop._id === (shopId))
    setInventory([...inventory, selectedItem])
}

const handleSelectedItem4 = (shopId) => {
    selectedItem = cart1.find((shop) => shop._id === (shopId))
    setInventory2([...inventory2, selectedItem])
}
const handleSelectedPotion = (shopId) => {
  selectedItem = potion.find((ship) => ship._id === (shopId))
  setCart2([...cart2, selectedItem])
}
const handleSelectedPotionInv = (shopId) => {
  selectedItem = potion.find((ship) => ship._id === (shopId))
  setInventory3([...inventory3, selectedItem])
}

const handleSelectedInv4 = (shopId) => {
  selectedItem = cannonball.find((ship) => ship._id === (shopId))
  setInventory4([...inventory4, selectedItem])
}


const handleSelectedItem2 = (shopId) => {
    selectedItem = loot.find((shop) => shop._id === (shopId))
    
    
    setInventory1([...inventory1, selectedItem])
}

const handleSelectedItemLootPotion = (shopId) => {
  selectedItem = potion.find((shop) => shop._id === (shopId))
  setInventory3([...inventory3, selectedItem])
}

const handleSelectedItem3 = (shopId) => {
    selectedItem = ship.find((ship) => ship._id === (shopId))
    setCart1([...cart1, selectedItem])
}

const handleSelectedQuestItems = (shopId) => {
  selectedItem = questItems.find((ship) => ship._id === (shopId))
  setInventory5([...inventory5, selectedItem])
}


const handleSelectedQuestDeck = (chestNumber) => {
  const selectedItems = questItems.filter((item) => item.chest.endsWith(chestNumber));
  setInventory5([...inventory5, ...selectedItems]);
};


const handleQuestHolder = (shopId) => {

  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = questHolder.slice(0,2)
  setQuestHolder([...limit, selectedItem])
}

const handleQuestHolder1 = (shopId) => {

  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = questHolder1.slice(0,1)
  setQuestHolder1([...limit, selectedItem])
}
const handleQuestHolder2 = (shopId) => {
 
  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = questHolder2.slice(0,2)
  setQuestHolder2([...limit, selectedItem])
}

const handleQuestHolder3 = (shopId) => {

  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = questHolder3.slice(0,2)
  setQuestHolder3([...limit, selectedItem])
}

const handleQuestHolder4 = (shopId) => {
  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = questHolder4.slice(0,3)
  setQuestHolder4([...limit, selectedItem])
}

const handleFragmentHolder = (shopId) => {
  let selectedItem = inventory5.find((quest) => quest._id === (shopId))
  let limit = fragmentHolder.slice(0,3)
  setMapFragmentHolder([...limit, selectedItem])
}

const handleSelectedItem5 = (shopId) => {
  selectedItem = inventory2.find((ship) => ship._id === (shopId))
  shipStats.splice(0,1)
  setShipStats([...shipStats, selectedItem])
}

const handleSelectedCannonball = (shopId) => {
  selectedItem = cannonball.find((ship) => ship._id === (shopId))
  setCart3([...cart3, selectedItem])
}

const handleSelectedCannon = (shopId) => {
  selectedItem = inventory4.find((ship) => ship._id === (shopId))
  setCannons([...cannons, ...Array.from({length: 10}, () => selectedItem)])
}

const handleSelectedCannonX20 = (shopId) => {
  selectedItem = inventory4.find((ship) => ship._id === (shopId))
  setCannons([...cannons, ...Array.from({length: 20}, () => selectedItem)])
}



   const removeProductInv = (id) => {
    const basketCopy = [...inventory];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory(basketCopy);
  };

  const removeProductLoot = (id) => {
    const basketCopy = [...loot];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setLoot(basketCopy);
  };

  const removeProductShipStat = (id) => {
    const basketCopy = [...shipStats];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setShipStats(basketCopy);
  };


  const removeProductInv1 = (id) => {
    const basketCopy = [...inventory1];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory1(basketCopy);
  };

  const removeProductInv4 = (id) => {
    const basketCopy = [...inventory4];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory4(basketCopy);
  };

  const removeProductInv2 = (id) => {
    const basketCopy = [...inventory2];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory2(basketCopy);
  };

  const removeProductCart = (id) => {
    const basketCopy = [...cart];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setCart(basketCopy);
  };

  const removeProductCart1 = (id) => {
    const basketCopy = [...cart1];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setCart1(basketCopy);
  };
  const removeProductCart2 = (id) => {
    const basketCopy = [...cart2];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setCart2(basketCopy);
  };

  const removeProductCannon = (id) => {
    const basketCopy = [...cannons];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setCannons(basketCopy);
  };

  const removeProductCart3 = (id) => {
    const basketCopy = [...cart3];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setCart3(basketCopy);
  };

  const removeProductPotion = (id) => {
    const basketCopy = [...inventory3];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory3(basketCopy);
  };

  const removeEnemyShip = (id) => {
    const basketCopy = [...enemyShip];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setEnemyShip(basketCopy);
  };

  const removePotionLoot = (id) => {
    const basketCopy = [...potion];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setPotion(basketCopy);
  };

  const removeQuestLoot = (id) => {
    const basketCopy = [...questItems];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setQuestItems(basketCopy);
  };
  const removeQuestInv = (id) => {
    const basketCopy = [...inventory5];
    const indexLocation = basketCopy.indexOf(id);
    basketCopy.splice(indexLocation, 1);
    setInventory5(basketCopy);
  };

  const removeQuestHolder = (id) => {
    const basketCopy = [...questHolder];
    basketCopy.splice(questHolder);
    setQuestHolder(basketCopy);
  };

  const removeQuestHolder1 = (id) => {
    const basketCopy = [...questHolder1];
    basketCopy.splice(questHolder);
    setQuestHolder1(basketCopy);
  };










 
    const [buyGold, setBuyGold] = useState(goldWallet.gold)



   

  return (
    <div>
        
    
    
 
    <Routes>
        <Route path={"/island-one/*"} element={<IslandOne handleQuestHolder={handleQuestHolder}hideChest={hideChest}pirateGold={pirateGold} ship={ship}potion={potion} removePotionLoot={removePotionLoot}
        removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        questHolder={questHolder} removeQuestHolder={removeQuestHolder} removeQuestInv={removeQuestInv} questLog={questLog} setQuestLog={setQuestLog}
        closeChat={closeChat} setCloseChat={setCloseChat} timeOutChat={timeOutChat} setTimeoutChat={setTimeoutChat} questBook={questBook} setQuestBook={setQuestBook}
        closeChat1={closeChat1} setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}questBook1={questBook1}questBook2={questBook2}setQuestBook2={setQuestBook2}
        questLog2={questLog2}closeChat2={closeChat2}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20} setSelectedOption={setSelectedOption}
        closeChat3={closeChat3} recruitDialogue1={recruitDialogue1} setRecruitDialogue1={setRecruitDialogue1}showRecruit1={showRecruit1}setShowRecruit1={setShowRecruit1}
        myShip={myShip} setMyShip={setMyShip}unlock={unlock} setUnlock={setUnlock}waitUnlock={waitUnlock} setWaitUnlock={setWaitUnlock}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo} levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>


        
        <Route path={"/island-two/*"} element={<IslandTwo  pirateGold={pirateGold} removePotionLoot={removePotionLoot} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        questLog1={questLog1} setQuestLog1={setQuestLog1} questHolder={questHolder} questHolder1={questHolder1} setQuestHolder1={setQuestHolder1}
        handleQuestHolder1={handleQuestHolder1} removeQuestHolder1={removeQuestHolder1}timeOutChat1={timeOutChat1}setTimeoutChat1={setTimeoutChat1}closeChat1={closeChat1}
         setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2} removeQuestInv={removeQuestInv}removeProductInv4={removeProductInv4}
           handleSelectedCannonX20={handleSelectedCannonX20} setSelectedOption={setSelectedOption}closeChat3={closeChat3}
           recruitDialogue2={recruitDialogue2} setRecruitDialogue2={setRecruitDialogue2}showRecruit2={showRecruit2}setShowRecruit2={setShowRecruit2}myShip1={myShip1} setMyShip1={setMyShip1}
           unlock1={unlock1} setUnlock1={setUnlock1}waitUnlock1={waitUnlock1} setWaitUnlock1={setWaitUnlock1}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
           level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
           levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

        <Route path={"/island-three/*"} element={<IslandThree  hideChest2={hideChest2}pirateGold={pirateGold} setHideChest1={setHideChest1} removePotionLoot={removePotionLoot} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        closeChat1={closeChat1}setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat}
         setCloseChat={setCloseChat} setQuestBook={setQuestBook}questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}
         removeProductInv4={removeProductInv4} handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}
         levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

        <Route path={"/island-four/*"} element={<IslandFour  removePotionLoot={removePotionLoot}pirateGold={pirateGold}hideChest3={hideChest3} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        closeChat1={closeChat1}setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook} questLog1={questLog1}questLog={questLog}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}
           removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}
           levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

          <Route path={"/island-five/*"} element={<IslandFive  removePotionLoot={removePotionLoot}pirateGold={pirateGold}hideChest3={hideChest3} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
          shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
          inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
          removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
          handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
          closeChat1={closeChat1} setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}
           removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}closeChat3={closeChat3} setCloseChat3={setCloseChat3}
          timeOutChat3={timeOutChat3} setTimeoutChat3={setTimeoutChat3} questBook3={questBook3} setQuestBook3={setQuestBook3}questHolder3={questHolder3} setQuestHolder3={setQuestHolder3}
          questLog3={questLog3} setQuestLog3={setQuestLog3}handleQuestHolder3={handleQuestHolder3}removeQuestInv={removeQuestInv} recruitDialogue={recruitDialogue} setRecruitDialogue={setRecruitDialogue}showRecruit={showRecruit}
          setShowRecruit={setShowRecruit} recruitIsland5={recruitIsland5} setRecruitIsland5={setRecruitIsland5} myShip3={myShip3} setMyShip3={setMyShip3} unlock3={unlock3} setUnlock3={setUnlock3}
          waitUnlock3={waitUnlock3} setWaitUnlock3={setWaitUnlock3} hideChest1={hideChest1}
          levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

          <Route path={"/island-six/*"} element={<IslandSix  removePotionLoot={removePotionLoot}pirateGold={pirateGold}hideChest3={hideChest3} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
          shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
          inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
          removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
          handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}closeChat1={closeChat1}
         setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}removeProductInv4={removeProductInv4}
           handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}
           levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

          <Route path={"/island-seven/*"} element={<IslandSeven  removePotionLoot={removePotionLoot}pirateGold={pirateGold}hideChest3={hideChest3} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
          shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
          inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
          removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
          handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}closeChat1={closeChat1}
         setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook} questHolder2={questHolder2} setQuestHolder2={setQuestHolder2} questLog2={questLog2} setQuestLog2={setQuestLog2}timeOutChat2={timeOutChat2} setTimeoutChat2={setTimeoutChat2}
           closeChat2={closeChat2} setCloseChat2={setCloseChat2}handleQuestHolder2={handleQuestHolder2} questLog={questLog} questLog1={questLog1} questBook2={questBook2}setQuestBook2={setQuestBook2} removeQuestInv={removeQuestInv}
           removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption} closeChat3={closeChat3}
           recruitDialogue3={recruitDialogue3} setRecruitDialogue3={setRecruitDialogue3}showRecruit3={showRecruit3}setShowRecruit3={setShowRecruit3}myShip2={myShip2} setMyShip2={setMyShip2}
           unlock2={unlock2} setUnlock2={setUnlock2}waitUnlock2={waitUnlock2} setWaitUnlock2={setWaitUnlock2}
           levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

<Route path={"/extra-area3/*"} element={<ExtraArea3  hideChest2={hideChest2}pirateGold={pirateGold} setHideChest1={setHideChest1} removePotionLoot={removePotionLoot} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        closeChat1={closeChat1}setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat}
         setCloseChat={setCloseChat} setQuestBook={setQuestBook}questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}
         removeProductInv4={removeProductInv4} handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
         level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo} levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
         levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

<Route path={"/extra-area1/*"} element={<ExtraArea1 handleQuestHolder={handleQuestHolder}hideChest={hideChest}pirateGold={pirateGold} ship={ship}potion={potion} removePotionLoot={removePotionLoot}
        removeProductPotion={removeProductPotion}inventory3={inventory3}
        shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
        inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
        removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
        handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
        questHolder={questHolder} removeQuestHolder={removeQuestHolder} removeQuestInv={removeQuestInv} questLog={questLog} setQuestLog={setQuestLog}
        closeChat={closeChat} setCloseChat={setCloseChat} timeOutChat={timeOutChat} setTimeoutChat={setTimeoutChat} questBook={questBook} setQuestBook={setQuestBook}
        closeChat1={closeChat1} setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}questBook1={questBook1}questBook2={questBook2}setQuestBook2={setQuestBook2}
        questLog2={questLog2}closeChat2={closeChat2}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20} setSelectedOption={setSelectedOption}
        closeChat3={closeChat3} recruitDialogue1={recruitDialogue1} setRecruitDialogue1={setRecruitDialogue1}showRecruit1={showRecruit1}setShowRecruit1={setShowRecruit1}
        myShip={myShip} setMyShip={setMyShip}unlock={unlock} setUnlock={setUnlock}waitUnlock={waitUnlock} setWaitUnlock={setWaitUnlock}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
        level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo} levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
        levelFive={levelFive}setLevelFive={setLevelFive}/>}/>
           
           <Route path={"/extra-area5/*"} element={<ExtraArea5  removePotionLoot={removePotionLoot}pirateGold={pirateGold}hideChest3={hideChest3} ship={ship}potion={potion} removeProductPotion={removeProductPotion}inventory3={inventory3}
          shipStats={shipStats}loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductInv={removeProductInv} 
          inventory={inventory} goldWallet={goldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductLoot={removeProductLoot}
          removeProductInv2={removeProductInv2} inventory2={inventory2} handleSelectedItem5={handleSelectedItem5} removeProductShipStat={removeProductShipStat} 
          handleSelectedItemLootPotion={handleSelectedItemLootPotion} cannons={cannons} inventory4={inventory4} inventory5={inventory5}setEquip={setEquip} equip={equip}  handleSelectedCannon={ handleSelectedCannon}
          closeChat1={closeChat1} setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}
           removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}closeChat3={closeChat3} setCloseChat3={setCloseChat3}
          timeOutChat3={timeOutChat3} setTimeoutChat3={setTimeoutChat3} questBook3={questBook3} setQuestBook3={setQuestBook3}questHolder3={questHolder3} setQuestHolder3={setQuestHolder3}
          questLog3={questLog3} setQuestLog3={setQuestLog3}handleQuestHolder3={handleQuestHolder3}removeQuestInv={removeQuestInv} recruitDialogue={recruitDialogue} setRecruitDialogue={setRecruitDialogue}showRecruit={showRecruit}
          setShowRecruit={setShowRecruit} recruitIsland5={recruitIsland5} setRecruitIsland5={setRecruitIsland5} myShip3={myShip3} setMyShip3={setMyShip3} unlock3={unlock3} setUnlock3={setUnlock3}
          waitUnlock3={waitUnlock3} setWaitUnlock3={setWaitUnlock3} hideChest1={hideChest1} revealPrompt4={revealPrompt4} setRevealPrompt4={setRevealPrompt4}hideChest2={hideChest2} setHideChest2={setHideChest2}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
          level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo} levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}
          levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

        <Route path="/shop" element={<Shop button={button} setButton={setButton}handleSelectedItem5={handleSelectedItem5}handleSelectedCannon={handleSelectedCannon} cart3={cart3} removeProductCart3={removeProductCart3} 
        handleSelectedCannonball={handleSelectedCannonball} cannonball={cannonball}inventory3={inventory3}cart2={cart2}
        handleSelectedPotion={handleSelectedPotion} potion={potion}inventory2={inventory2}
         removeProductCart1={removeProductCart1}handleSelectedItem3={handleSelectedItem3}
         loot={loot} cart1={cart1} setBuyGold={setBuyGold} buyGold={buyGold} pirateGold={pirateGold}
        goldWallet={goldWallet} shopItems={shopItems}handleSelectedItem={handleSelectedItem} cart={cart}
        removeProductInv={removeProductInv} removeProductCart={removeProductCart}inventory={inventory} handleSelectedItem1={handleSelectedItem1}
        setGoldWallet={setGoldWallet} inventory1={inventory1} removeProductInv1={removeProductInv1} ship={ship} handleSelectedItem4={handleSelectedItem4}
        removeProductInv2={removeProductInv2}  handleSelectedPotionInv={handleSelectedPotionInv}
        removeProductCart2={removeProductCart2} removeProductPotion={removeProductPotion} handleSelectedInv4={handleSelectedInv4} inventory4={inventory4} inventory5={inventory5}
        removeProductInv4={removeProductInv4} removeProductShipStat={removeProductShipStat} shipStats={shipStats} setEquip={setEquip} equip={equip} cannons={cannons}
         shopMenu={shopMenu} setShopMenu={setShopMenu} closeChat1={closeChat1}setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
           questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}handleSelectedCannonX20={handleSelectedCannonX20}setSelectedOption={setSelectedOption}
           handleSelectedQuestDeck={handleSelectedQuestDeck}mapQuest={mapQuest} setMapQuest={setMapQuest} hasRunOnce2={hasRunOnce2} setHasRunOnce2={setHasRunOnce2} checkInv6={checkInv6}setCheckInv6={setCheckInv6} />}/>
        
        <Route path="/" element={<Home />}/>
        <Route path="/defeat" element={<Defeat shipStats={shipStats}/>}/>
       

        <Route path="/island-map" element={<PirateMap shipStats={shipStats} pirateGold={pirateGold} inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} inventory5={inventory5}removeProductInv={removeProductInv} loot={loot}
        handleSelectedCannon={handleSelectedCannon} removeProductPotion={removeProductPotion} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip={equip} setEquip={setEquip}  removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} questBook={questBook} setQuestBook={setQuestBook} questLog={questLog} setQuestLog={setQuestLog}
        closeChat={closeChat} setCloseChat={setCloseChat}setQuestBook1={setQuestBook1} questLog1={questLog1} setQuestLog1={setQuestLog1}closeChat1={closeChat1} setCloseChat1={setCloseChat1} questBook1={questBook1}
        questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}removeProductInv4={removeProductInv4} handleSelectedCannonX20={handleSelectedCannonX20}selectedOption={selectedOption}
         setSelectedOption={setSelectedOption} changeShipClass={changeShipClass} setChangeShipClass={setChangeShipClass} closeChat3={closeChat3}changeShipPrompt={changeShipPrompt} setChangePrompt={setChangePrompt}
         questLog3={questLog3}sunkShip={sunkShip} setSunkShip={setSunkShip}sunkShip1={sunkShip1} setSunkShip1={setSunkShip1} sunkShip2={sunkShip2} setSunkShip2={setSunkShip2}sunkShip3={sunkShip3} setSunkShip3={setSunkShip3}
         changeSink={changeSink} setChangeSink={setChangeSink}changeSink1={changeSink1} setChangeSink1={setChangeSink1}changeSink2={changeSink2} setChangeSink2={setChangeSink2}changeSink3={changeSink3} setChangeSink3={setChangeSink3}
         deleteShip={deleteShip} setDeleteShip={setDeleteShip}deleteShip1={deleteShip1} setDeleteShip1={setDeleteShip1} deleteShip2={deleteShip2} setDeleteShip2={setDeleteShip2}deleteShip3={deleteShip3} setDeleteShip3={setDeleteShip3}
         sinkPrompt={sinkPrompt} setSinkPrompt={setSinkPrompt} levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation} level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo}
         levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour} levelFive={levelFive}setLevelFive={setLevelFive}/>}/>

        <Route path="/enemy" element={<EnemyBattle animateEnemy={animateEnemy} setAnimateEnemy={setAnimateEnemy} removeEnemyShip={removeEnemyShip} board={board}  setBoard={setBoard}enemyShip={enemyShip}
         ship={ship}removeProductCannon={removeProductCannon} shipStats={shipStats} inventory4={inventory4}  inventory3={inventory3}  inventory={inventory} inventory2={inventory2} inventory5={inventory5}
        goldWallet={goldWallet} removeProductInv={removeProductInv} inventory1={inventory1} loot={loot} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        removeProductPotion={removeProductPotion} removeProductShipStat={removeProductShipStat} cannons={cannons}handleSelectedCannon={handleSelectedCannon} pirateGold={pirateGold}
        animateShip={animateShip} setAnimateShip={setAnimateShip}closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}
          setSunkShip={setSunkShip}/>}/>

        <Route path="/enemy1" element={<EnemyBattle1 animateEnemy={animateEnemy} 
        setAnimateEnemy={setAnimateEnemy}board1={board1} setBoard1={setBoard1}enemyShip={enemyShip} 
        ship={ship}removeProductCannon={removeProductCannon} shipStats={shipStats}inventory4={inventory4}
          inventory3={inventory3}  inventory={inventory} inventory2={inventory2} inventory5={inventory5}
        goldWallet={goldWallet} removeProductInv={removeProductInv} inventory1={inventory1} 
        loot={loot} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        removeProductPotion={removeProductPotion} removeProductShipStat={removeProductShipStat}
         cannons={cannons}handleSelectedCannon={handleSelectedCannon} pirateGold={pirateGold}
        animateShip={animateShip} setAnimateShip={setAnimateShip}closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}
          setSunkShip1={setSunkShip1}/>}/>
      
        <Route path="/enemy2" element={<EnemyBattle2 animateEnemy={animateEnemy} setAnimateEnemy={setAnimateEnemy}board2={board2} setBoard2={setBoard2}enemyShip={enemyShip} ship={ship}
        removeProductCannon={removeProductCannon} shipStats={shipStats}inventory4={inventory4}  inventory3={inventory3}  inventory={inventory} inventory2={inventory2}
        goldWallet={goldWallet} removeProductInv={removeProductInv} inventory1={inventory1} loot={loot} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        removeProductPotion={removeProductPotion} removeProductShipStat={removeProductShipStat} cannons={cannons}handleSelectedCannon={handleSelectedCannon} pirateGold={pirateGold}
        animateShip={animateShip} setAnimateShip={setAnimateShip}inventory5={inventory5}closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} removeProductInv4={removeProductInv4}setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}handleSelectedCannonX20={handleSelectedCannonX20}
          setSunkShip2={setSunkShip2}/>}/>

        <Route path="/enemy3" element={<EnemyBattle3 board3={board3}animateEnemy={animateEnemy} setAnimateEnemy={setAnimateEnemy} setBoard3={setBoard3}enemyShip={enemyShip} ship={ship}
        removeProductCannon={removeProductCannon} shipStats={shipStats}inventory4={inventory4}  inventory3={inventory3}  inventory={inventory} inventory2={inventory2}
        goldWallet={goldWallet} removeProductInv={removeProductInv} inventory1={inventory1} loot={loot} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        removeProductPotion={removeProductPotion} removeProductShipStat={removeProductShipStat} cannons={cannons}handleSelectedCannon={handleSelectedCannon} pirateGold={pirateGold}
        animateShip={animateShip} setAnimateShip={setAnimateShip} inventory5={inventory5}closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}
          setSunkShip3={setSunkShip3}/>}/>

        <Route path="/ship-deck3" element={<ShipDeck3 pirateGold={pirateGold} handleSelectedQuestItems={handleSelectedQuestItems} shipStats={shipStats} revealPrompt3={revealPrompt3} setRevealPrompt3={setRevealPrompt3}
        loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} potion={potion} removeProductPotion={removeProductPotion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
        removeProductLoot={removeProductLoot}   inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} removeProductInv={removeProductInv}
        handleSelectedCannon={handleSelectedCannon} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip2={equip2} setEquip2={setEquip2} removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2}hideChest1={hideChest1} setHideChest1={setHideChest1}
        questItems={questItems} removeQuestLoot={removeQuestLoot}inventory5={inventory5} closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}handleSelectedCannonX20={handleSelectedCannonX20}/>}/>

        <Route path="/ship-deck" element={<ShipDeck handleSelectedQuestItems={handleSelectedQuestItems} pirateGold={pirateGold}revealPrompt2={revealPrompt2} setRevealPrompt2={setRevealPrompt2} shipStats={shipStats}
        loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} potion={potion} removeProductPotion={removeProductPotion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
        removeProductLoot={removeProductLoot}  inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} removeProductInv={removeProductInv}
        handleSelectedCannon={handleSelectedCannon} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip1={equip1} setEquip1={setEquip1} removeEquip1={removeEquip1} setRemoveEquip1={setRemoveEquip1}
        questItems={questItems} removeQuestLoot={removeQuestLoot}inventory5={inventory5} closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}hideChest={hideChest} setHideChest={setHideChest}/>}/>

        <Route path="/ship-deck1" element={<ShipDeck1 handleSelectedQuestItems={handleSelectedQuestItems}pirateGold={pirateGold}setHideChest1={setHideChest1} hideChest1={hideChest1}revealPrompt1={revealPrompt1} setRevealPrompt1={setRevealPrompt1} shipStats={shipStats}
        loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} potion={potion} removeProductPotion={removeProductPotion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
        removeProductLoot={removeProductLoot}   inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} removeProductInv={removeProductInv}
        handleSelectedCannon={handleSelectedCannon} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip2={equip2} setEquip2={setEquip2} removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2} inventory5={inventory5}
        questItems={questItems} removeQuestLoot={removeQuestLoot} closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}/>}/>

        <Route path="/ship-deck2" element={<ShipDeck2  handleSelectedQuestItems={handleSelectedQuestItems}pirateGold={pirateGold}setHideChest={setHideChest} hideChest={hideChest} shipStats={shipStats} revealPrompt={revealPrompt}setRevealPrompt={setRevealPrompt}
        loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} potion={potion} removeProductPotion={removeProductPotion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
        removeProductLoot={removeProductLoot}  inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} removeProductInv={removeProductInv}
        handleSelectedCannon={handleSelectedCannon} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip2={equip2} setEquip2={setEquip2}  removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2} inventory5={inventory5}
        questItems={questItems} removeQuestLoot={removeQuestLoot} closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2}/>}/>

        <Route path="/my-ship" element={<MyShipDeck pirateGold={pirateGold}setHideChest={setHideChest} hideChest={hideChest} shipStats={shipStats} revealPrompt={revealPrompt}setRevealPrompt={setRevealPrompt}
        loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} potion={potion} removeProductPotion={removeProductPotion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
        removeProductLoot={removeProductLoot}   inventory={inventory} cannons={cannons} inventory3={inventory3} handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2} removeProductInv1={removeProductInv1}removeProductInv2={removeProductInv2} inventory4={inventory4} removeProductInv={removeProductInv}
        handleSelectedCannon={handleSelectedCannon} goldWallet={goldWallet} removeProductShipStat={removeProductShipStat} inventory1={inventory1}
        equip2={equip2} setEquip2={setEquip2}  removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2}inventory5={inventory5}closeChat1={closeChat1}
        setCloseChat1={setCloseChat1} setQuestBook1={setQuestBook1}  questBook1={questBook1}closeChat={closeChat} setCloseChat={setCloseChat} setQuestBook={setQuestBook}
          questBook={questBook}questBook2={questBook2}setQuestBook2={setQuestBook2}questLog2={questLog2} closeChat2={closeChat2} myShip={myShip}
          myShip1={myShip1} myShip2={myShip2} myShip3={myShip3}deckTalk1={deckTalk1} setDeckTalk1={setDeckTalk1}deckTalk={deckTalk} setDeckTalk={setDeckTalk}
          handleSelectedQuestDeck={handleSelectedQuestDeck}deckTalk2={deckTalk2}setDeckTalk2={setDeckTalk2}deckTalk3={deckTalk3}setDeckTalk3={setDeckTalk3}
          deckTalk4={deckTalk4}setDeckTalk4={setDeckTalk4}deckTalk5={deckTalk5}setDeckTalk5={setDeckTalk5}mapQuest={mapQuest} setMapQuest={setMapQuest} setMapQuest1={setMapQuest1}
          mapQuest1={mapQuest1}deckTalk6={deckTalk6} setDeckTalk6={setDeckTalk6} deckTalk7={deckTalk7} setDeckTalk7={setDeckTalk7}nextQuest={nextQuest} setNextQuest={setNextQuest}
          nextQuest1={nextQuest1} setNextQuest1={setNextQuest1}nextQuest2={nextQuest2} setNextQuest2={setNextQuest2} handleQuestHolder4={handleQuestHolder4} questHolder4={questHolder4}
          mapPiece={mapPiece}setMapPiece={setMapPiece}closeChat3={closeChat3} handleFragmentHolder={handleFragmentHolder} fragmentHolder={fragmentHolder}setMapFragmentHolder={setMapFragmentHolder}
          removeQuestInv={removeQuestInv}initiateChat={initiateChat}setInitiateChat={setInitiateChat}startPrompt={startPrompt}setStartPrompt={setStartPrompt}
          questFragment={questFragment} setQuestFragment={setQuestFragment}hide={hide} setHide={setHide}hasRunOnce={hasRunOnce} setHasRunOnce={setHasRunOnce}timeOutChat4={timeOutChat4}  setTimeoutChat4={setTimeoutChat4}
          closeChat4={closeChat4} setCloseChat4={setCloseChat4}hasRunOnce1={hasRunOnce1} setHasRunOnce1={setHasRunOnce1}timeOutChat5={timeOutChat5} setTimeoutChat5={setTimeoutChat5} hasRunOnce2={hasRunOnce2} setHasRunOnce2={setHasRunOnce2}
           setTimeoutChat6={setTimeoutChat6}checkInv6={checkInv6}levelAnimation={levelAnimation} setLevelAnimation={setLevelAnimation}
           level={level} setLevel={setLevel} levelTwo={levelTwo} setLevelTwo={setLevelTwo} levelThree={levelThree}setLevelThree={setLevelThree}levelFour={levelFour} setLevelFour={setLevelFour}levelFive={levelFive}setLevelFive={setLevelFive}

          />}/>
        </Routes>
   

    
    </div>
    
  )
}

export default PirateContainer