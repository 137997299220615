import React,{useState} from 'react'

const InventoryItem3 = ({bag, shipStats, removeProductPotion, goldWallet, currentPath,
  showHeal,setShowHeal}) => {

   const [changeClass, setChangeClass]=useState("show-heal")
   const [Boolean, setBoolean]=useState(false)
   

      const handleHeal = ()=>{
     
        setShowHeal(<h2 className={changeClass}>+{bag.potion} Health</h2>)
          setChangeClass("show-heal1")
  
        setBoolean(true)
        setTimeout(() => {
          setShowHeal()
          setChangeClass("show-heal")
         setBoolean(false)
      
        },3000)
        let healthLimit;
        shipStats.map((shipStats, index)=>{
            if(shipStats.health < 100)
            {
              healthLimit = shipStats.health += bag.potion;
              removeProductPotion(bag)
            }
            if(shipStats.health > 100){
              shipStats.health = 100
            }

            return <li key={index}>{ healthLimit}
            
             </li>
            
          })
      }

      const handleHeal1 = ()=>{
        let healthLimit;
        shipStats.map((shipStats, index)=>{
          if(shipStats.health < 100)
          {
            healthLimit = shipStats.health += bag.potion;
            removeProductPotion(bag)
          }
          if(shipStats.health > 100){
            shipStats.health = 100
          }
  
          return <li key={index}>{ healthLimit}
          
           </li>
          
        })
  
      }





      const handleSell =()=>{
        removeProductPotion(bag)
        const sellItems = goldWallet.map((goldWallet, index)=>{
            return <li key={index}>{goldWallet.gold += bag.price}</li>
                        })
    }
  return (
    <div>
        
        <div className='game-item'>
        <p>
        <img src={bag.img}alt="" height="50" width="50"/><br/>
        
        
        {  currentPath === "/enemy"?
          (<button onClick={handleHeal}className="load-button">Heal</button>)
        :null}
                { currentPath === "/enemy1"?
          (<button onClick={handleHeal}className="load-button">Heal</button>)
        :null}
                { currentPath === "/enemy2"?
          (<button onClick={handleHeal}className="load-button">Heal</button>)
        :null}
                { currentPath === "/enemy3"?
          (<button onClick={handleHeal}className="load-button">Heal</button>)
        :null}

{ currentPath === "/island-one"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}

{ currentPath === "/island-two"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}

{ currentPath === "/island-three"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}
{ currentPath === "/island-four"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}
{ currentPath === "/island-five"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}
{ currentPath === "/island-six"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}

{ currentPath === "/island-seven"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}
{ currentPath === "/island-map"?
          (<button onClick={handleHeal1}className="load-button">Heal</button>)
        :null}


            { currentPath === "/shop" ?
          (<button onClick={handleSell}className="load-button">Sell</button>)
        :null}




      
        
        </p>
        
        
    </div>
    </div>
  )
}

export default InventoryItem3

