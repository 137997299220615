import React, {useState, useEffect} from 'react'
import '../CSS/island-background.css'
import { Link} from 'react-router-dom'
import LootListD7 from '../LootLists/LootListD7'
import LootListD8 from '../LootLists/LootListD8'
import Inventory from '../Inventory'
import ShipDeck3 from './ShipDeck3'
import WalletItem from '../MapItems/WalletItem'

const MyShipDeck= ({ shipStats, loot, removeProductPotion, pirateGold, inventory,
cannons, inventory3, goldWallet, handleSelectedItem5, inventory2,removeProductInv,
inventory1, removeProductInv1, removeProductInv2, inventory4, handleSelectedCannon,
removeProductShipStat, removeEquip2, setRemoveEquip2, equip2, setEquip2,inventory5,
closeChat1, setQuestBook1,questBook1,closeChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2,
 myShip, myShip1, myShip2, myShip3,deckTalk1, setDeckTalk1,deckTalk, setDeckTalk,
 handleSelectedQuestDeck, deckTalk2, setDeckTalk2,deckTalk3, setDeckTalk3, deckTalk4, setDeckTalk4,
 deckTalk5, setDeckTalk5,mapQuest1, setMapQuest,setMapQuest1,deckTalk6, setDeckTalk6,deckTalk7, setDeckTalk7,
 nextQuest, setNextQuest,nextQuest1, setNextQuest1,nextQuest2,setNextQuest2,handleQuestHolder4,
questHolder4,mapPiece, setMapPiece, closeChat3, handleFragmentHolder, setFragmentHolder, 
fragmentHolder,removeQuestInv,initiateChat, setInitiateChat,startPrompt, setStartPrompt,questFragment,
 setQuestFragment,hide,setHide,hasRunOnce, setHasRunOnce,hasRunOnce1, setHasRunOnce1,timeOutChat4, setTimeoutChat4,closeChat4, setCloseChat4,
 setTimeoutChat6, setTimeoutChat5, checkInv6,levelAnimation,setLevelAnimation,level,setLevel,levelTwo,setLevelTwo,
 levelThree,setLevelThree,levelFour, setLevelFour,levelFive, setLevelFive}) => {

  const [nightDay, setNightDay] = useState()
  const [showBag, setShowBag] = useState(false)
  const [logBook, setLogBook] = useState(false)
  const [displayReward, setDisplayReward]=useState()
  const [displayReward1, setDisplayReward1]=useState()
  const [rewardAnimation, setRewardAnimation]=useState('display-deck')
  const [rewardAnimation1, setRewardAnimation1]=useState('display-deck2')
  const [questCompleted, setQuestCompleted]=useState()
  const [questCompleted1, setQuestCompleted1]=useState()
  const [dialogueDeck, setDialogueDeck] =useState(false)
  const [dialogueDeck1, setDialogueDeck1] =useState(false)
  const [questCompleteDeck, setQuestCompleteDeck]= useState(true)
  const [questCompleteDeck1, setQuestCompleteDeck1]= useState(true)
  const [checkInv5, setCheckInv5]= useState(false)


  const [displayReward2, setDisplayReward2]=useState()
  const [rewardAnimation2, setRewardAnimation2]=useState('display-deck4')
  const [questCompleted2, setQuestCompleted2]=useState()
  
  const walletItems = goldWallet.map((goldWallet, index)=>{
    return <WalletItem
      key={index}
      goldWallet={goldWallet}
      level={level}
      setLevel={setLevel}
      levelAnimation={levelAnimation}
      setLevelAnimation={setLevelAnimation}
      levelTwo={levelTwo}
      setLevelTwo={setLevelTwo}
      levelThree={levelThree}
      setLevelThree={setLevelThree}
      levelFour={levelFour}
      setLevelFour={setLevelFour}
      levelFive={levelFive}
      setLevelFive={setLevelFive}
    />})

  

 




 

  const handleQuestDeck=()=>{
    setDeckTalk1(true)
    setDeckTalk(false)
  }

  const handleQuestDeck1=()=>{
    setDeckTalk3(true)
    setDeckTalk2(false)
    setNextQuest(false)
  }

  const handleQuestDeck2=()=>{
    setDeckTalk5(true)
    setDeckTalk4(false)
    
  }

  const handleQuestDeck3=()=>{
    setDeckTalk7(true)
    setDeckTalk6(false)
    setNextQuest1(false)
    setNextQuest2(false)
    
  }
  
  const handleMapFragment=()=>{
    handleSelectedQuestDeck(9)
    setDeckTalk1(false)
    setNextQuest(true)
  }

  const handleMapFragment1=()=>{
    handleSelectedQuestDeck("n")
    setDeckTalk3(false)
    setNextQuest2(true)
    
    
  }
  const handleMapPiece=()=>{
    setMapPiece(false)
    setQuestFragment(true)
 
  }
  const handleMapFragment2=()=>{
    // handleSelectedQuestDeck("o")
    setDeckTalk5(false)
    setMapQuest(true)
    setNextQuest1(true)
  }

  const handleMapFragment3=()=>{
   
    setDeckTalk7(false)
    setMapQuest1(true)

  }

  useEffect(()=>{
    if(inventory5.length === 4 && !hasRunOnce )
    {
      setMapPiece(true);
      setHasRunOnce(true);
    }
  },[inventory5.length,hasRunOnce])
  const selectedInv = inventory5.filter(items => items.chest.endsWith("o"));
  useEffect(()=>{
   
    if(selectedInv.length === 1 && !hasRunOnce1)
    {
     
      setHasRunOnce1(true);
      setCheckInv5(true)
     
      
    }
  },[hasRunOnce1, selectedInv.length])


 
  

  const handleParrot=()=>{
     handleSelectedQuestDeck("e")
     setMapQuest1(false)
  }
  useEffect(()=>{
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
   }
   const randomValue = getRandomInt(2)
      if(randomValue === 1){
          setNightDay('ship-deck')
      }
      else if(randomValue === 0){
          setNightDay('ship-deck-night')
      }

  },[])

  const handleBag =()=>{
    setShowBag(!showBag)
  }
  const handleLogBook=()=>{
    setLogBook(!logBook)
  }
  if(questLog === true){
    setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
    
  }
  if(closeChat === true){
    setQuestBook("Quest Completed")
  }
  if(questLog1 === true){
    setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
  }
  if(closeChat1 === true){
    setQuestBook1("Quest Completed")
  }
  
  if(questLog2 === true){
    setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
  }
  if(closeChat2 === true){
    setQuestBook2("Quest Completed")
  }
useEffect(()=>{
  if(myShip && myShip1 && myShip2 && myShip3 === true)
  {setInitiateChat(true)}
 
}, [initiateChat])
  const currentPath = window.location.pathname;
useEffect(()=>{
  if(closeChat3 === true){
    setStartPrompt(true)
  }
})


const fragmentFilter1 = fragmentHolder.filter(fragment => fragment.item.endsWith("1"))
const fragmentFilter2 = fragmentHolder.filter(fragment => fragment.item.endsWith("2"))
const fragmentFilter3 = fragmentHolder.filter(fragment => fragment.item.endsWith("3"))
const fragmentFilter4 = fragmentHolder.filter(fragment => fragment.item.endsWith("4"))

const fragmentHolderItems = fragmentHolder.map((fragmentHolder)=>{
    return <li className={fragmentFilter2.includes(fragmentHolder) ? 'fragment2' : fragmentFilter1.includes(fragmentHolder) ? 'fragment1' : fragmentFilter3.includes(fragmentHolder) ? 'fragment3' :fragmentFilter4.includes(fragmentHolder) ? 'fragment4' :''} key={fragmentHolder._id}><img src={fragmentHolder.img} alt="" height="100" width={"100"}/></li>
})
const handleHide=()=>{
  setHide(false)
}
const handleShow=()=>{
  setHide(true)
}

const handleComplete=(reward, reward1)=>{
  
  setRewardAnimation('display-deck1')
  setQuestCompleted("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  
  setCloseChat4(false)
  
  setDisplayReward(<h2 >+{reward}XP<br/>+${reward1}</h2>)
 
  
  setTimeout(()=>{
    setDisplayReward()
    setQuestCompleted()
    setTimeoutChat4(false)
   
  }, 3000)
} 

const handleComplete1=(reward, reward1)=>{
  
  setRewardAnimation1('display-deck3')
  setQuestCompleted1("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  
  
  
  setQuestCompleteDeck(false)
  setDisplayReward1(<h2 >+{reward}XP<br/>+${reward1}</h2>)
 
  
  setTimeout(()=>{
    setDisplayReward1()
    setQuestCompleted1()
    setTimeoutChat5(false)
   
  }, 3000)
} 

const handleComplete2=(reward, reward1)=>{
  
  setRewardAnimation2('display-deck5')
  setQuestCompleted2("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  
 
  
  setQuestCompleteDeck1(false)
  setDisplayReward2(<h2 >+{reward}XP<br/>+${reward1}</h2>)
 
  
  setTimeout(()=>{
    setDisplayReward2()
    setQuestCompleted2()
    setTimeoutChat6(false)
   
  }, 3000)
} 
const handleTalk=()=>{
  setDialogueDeck(true)
  
}
const handleTalk1=()=>{
  setDialogueDeck1(true)
 
}
  return (
    <div className={nightDay}>
          <h2 className='quest-completed-deck'>{questCompleted}</h2>
          <h2 className='quest-completed-deck1'>{questCompleted1}</h2>
      <div class={rewardAnimation}>{displayReward}</div>
      <div class={rewardAnimation1}>{displayReward1}</div>

      <h2 className='quest-completed-deck2'>{questCompleted2}</h2>
      <div class={rewardAnimation2}>{displayReward2}</div>
{closeChat4 ?<div>
{!hide ?<div className='deck-show' onClick={handleShow}><p>Show Quest</p></div>:null}
{questFragment && hide ?<div className='quest-fragment'><button onClick={handleHide} className={'hide'}>Hide</button>{fragmentHolderItems}<img src={require("../img/map.png")} alt="" height="100" width="100" className='map-quest'/>
<p className='deck-font'> Fragments {fragmentHolder.length}/4</p>{fragmentHolder.length === 4 ?<button className='complete-button' onClick={()=>handleComplete(220, 100)}>Complete Quest</button>:null}</div>:null}
</div>:null}

             {myShip && myShip1 && myShip2 && myShip3 ?null:(startPrompt ?<div className='talk-initiate' ><p>We will wait until all four of the pirates are present before we assemble the map fragments! </p></div>:null)}
             {myShip ?<img src={require('../img/pirate.png')} alt="" height="300" width="300" className='my-pirate'/>: null}

             {myShip && deckTalk && initiateChat?<div className='talk-shipDeck' onClick={handleQuestDeck}><p>Talk?</p></div>:null}
             {deckTalk1 ?<div className='talk-shipDeck1' ><p>Now that I'm part of yer crew I have this map fragment I came by need 3 more to use though <button onClick={handleMapFragment}>Collect</button></p></div>:null}

             {myShip1 ?<img src={require('../img/m-pirate.png')} alt="" height="300" width="300" className='my-pirate1'/>:null}

             {myShip1 && deckTalk4 && nextQuest?<div className='talk-shipDeck4' onClick={handleQuestDeck2}><p>Talk?</p></div>:null}
             {deckTalk5 ?<div className='talk-shipDeck5' ><p>unfortunatley the map fragment that I have I sold it to the shop you should find it in there! <button onClick={handleMapFragment2}>Accept</button></p></div>:null}


             {!dialogueDeck1 && !closeChat4 && checkInv6 ?<div className='talk-shipDeck6' ><p onClick={handleTalk1}>Talk?</p></div>:null}
            {dialogueDeck1 && questCompleteDeck1 ?<div className='talk-shipDeck7' ><p>you found me parrot! I be pleased as a barnacle-covered clam that ye found the map piece! <button onClick={()=>handleComplete2(200, 100)}>Complete Quest</button></p></div>:null}

             {
  !dialogueDeck && !closeChat4 && checkInv5 ? (
    <div className='talk-shipDeck4' onClick={handleTalk}>
      <p>Talk?</p>
    </div>
  ) : (
    null
  )
}

{
  dialogueDeck && questCompleteDeck ? (
    <div className='talk-completeDeck'>
      <p>
        Thanks for retrieving it now we have what we need!
        <button onClick={() => handleComplete1(200, 120)}>Complete Quest</button>
      </p>
    </div>
  ) : (
    null
  )
}
{walletItems}

             {myShip2 ?<img src={require('../img/parrot-pirate.png')} alt="" height="300" width="300" className='my-pirate2'/>:null}

             {myShip2 && deckTalk2 && nextQuest1?<div className='talk-shipDeck2' onClick={handleQuestDeck1}><p>Talk?</p></div>:null}
             {deckTalk3 ?<div className='talk-shipDeck3' ><p>Now that I'm part of yer crew I have this map fragment I came by need 3 more to use though <button onClick={handleMapFragment1}>Collect</button></p></div>:null}

             {mapPiece ?<div className='talk-map' ><p>Should we piece it all together mateys?<br/> <button onClick={handleMapPiece}>Accept</button></p></div>:null}

             {myShip3 ?<img src={require('../img/pirate-lady-drink.png')} alt="" height="300" width="300" className='my-pirate3'/>:null}

             {myShip3 && deckTalk6 && nextQuest2?<div className='talk-shipDeck6' onClick={handleQuestDeck3}><p>Talk?</p></div>:null}
             {deckTalk7 ?<div className='talk-shipDeck7' ><p>unfortunatley the map fragment that I have my parrot must of stole it and I cant find him anywhere!! <button onClick={handleMapFragment3}>Accept</button></p></div>:null}
             {mapQuest1 ?<img src={require("../img/parrot.png")}alt="" height="100" width="100" className='parrotDeck' onClick={handleParrot}/>:null}




            
    <div className='log-book-icon3' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book1'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
    <h3 className='shopGold'>{pirateGold}</h3>
    
    
  
    <div className='bag-icon2'>
    <div onClick={handleBag} >
    <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'/>
    </div>
    </div>
  





    <Link to="/island-map" >
    <div className='map-icon2'><img src={require('../img/map.png')} alt="" height='300' width='300'/></div>
    </Link>
    {showBag?<div className='inventory-shipDeck' >
    <Inventory currentPath ={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
        inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip2={setEquip2} equip2={equip2} removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5} handleFragmentHolder={handleFragmentHolder} removeQuestInv={removeQuestInv} questFragment={questFragment}
       />

       </div>:null}

    
    
    </div>
  )
}

export default MyShipDeck