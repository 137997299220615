import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from './NavBar'
import './CSS/island-background.css'

const Home = () => {
  return (
    <div className='title-screen'>
      <h1 className='game-title'>Chest of Perils</h1>
      <br/>
    <Link to="island-map">
      <h3 className='start'>Start</h3>
    </Link> 
        
    </div>
    
  )
}

export default Home