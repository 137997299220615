
const baseURL = 'http://localhost:9000/api/gold'
const baseURL1 = 'http://localhost:9000/api/shopItems'
const baseURL2 = 'http://localhost:9000/api/loot'
const baseURL3 = 'http://localhost:9000/api/ship'
const baseURL4 = 'http://localhost:9000/api/potion'
const baseURL5 = 'http://localhost:9000/api/cannonball'
const baseURL6 = 'http://localhost:9000/api/enemyShip'
const baseURL7 = 'http://localhost:9000/api/questItems'
const baseURL8 = 'http://localhost:9000/api/xpPoints'




const PirateService = {
 
     findGold() {
        return fetch(baseURL).then((res) => res.json())
      },
      findShopItems() {
        return fetch(baseURL1).then((res) => res.json())
      },
      findLootItems() {
        return fetch(baseURL2).then((res) => res.json())
      },
      findShip() {
        return fetch(baseURL3).then((res) => res.json())
      },
      findPotion() {
        return fetch(baseURL4).then((res) => res.json())
      },
      findCannonball() {
        return fetch(baseURL5).then((res) => res.json())
      },
      findEnemyShip() {
        return fetch(baseURL6).then((res) => res.json())
      },
      findQuestItems() {
        return fetch(baseURL7).then((res) => res.json())
      },
      findXpPoints() {
        return fetch(baseURL8).then((res) => res.json())
      },

      editGold(id, payload) {
        return fetch(baseURL + id, {
          method: 'PUT',
          body: JSON.stringify(payload),
          headers: { 'Content-Type': 'application/json' },
        }).then((res) => res.json())
      },
    
}


export default PirateService