import React from 'react'
import '../CSS/shop.css'

const LootQuestItem = ({quest, handleSelectedQuestItems,removeQuestLoot}) => {

 const handleTransfer = (e) =>{
  handleSelectedQuestItems(e.target.value)
  removeQuestLoot(quest)
 }
  return (
    <div className='game-item1'><img src={quest.img} alt="" height='50' width="50"/><button onClick={handleTransfer}value={quest._id}className="load-button">Loot</button></div>
  )
}

export default LootQuestItem