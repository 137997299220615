import React from 'react'

const InventoryItem4 = ({bag, goldWallet,removeProductInv4,handleSelectedCannon,currentPath,handleSelectedCannonX20}) => {

const handleSell =()=>{
  const sellItems = goldWallet.map((goldWallet, index)=>{
    return <li key={index}>{goldWallet.gold += bag.price}</li>
        })
        removeProductInv4(bag)
        }


const loadCannon=(e)=>{
  if(bag.quantity === 10)
   { handleSelectedCannon(e.target.value) }
  
    if(bag.quantity === 20){handleSelectedCannonX20(e.target.value)}

    removeProductInv4(bag)
}
  
  return (
  
        
        <div className='game-item'>
        <p>
        <img src={bag.img} alt="" width="40" height="40"/>x{bag.quantity}<br/>
            { currentPath === "/shop" ?
          (<button onClick={handleSell}className="load-button">Sell</button>)
        :null}
        <br/>
    {
    currentPath === "/shop" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/island-map" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/island-one" ?
    (<button onClick={loadCannon} value={bag._id} className="load-button">Load</button>):null
    }
    {
    currentPath === "/island-two" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/island-three" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/island-four" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
        {
    currentPath === "/island-five" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
            {
    currentPath === "/island-six" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
            {
    currentPath === "/island-seven" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/enemy" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/enemy1" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/enemy2" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
    {
    currentPath === "/enemy3" ?
    (<button onClick={loadCannon} value={bag._id}className="load-button">Load</button>):null
    }
  
    </p>
        
        
    </div>
 
  )
}

export default InventoryItem4