import React from 'react'

const ShopItems = ({shop, handleSelectedItem}) => {

    const handleBuyItem = (e) =>{
        handleSelectedItem(e.target.value)
    }
  return (
    <div className='game-item'>
        <p>{shop.weapon}{shop.potion} price: {shop.price}<button onClick={handleBuyItem}className="load-button" value={shop._id}>Buy</button></p>
    </div>
  )
}

export default ShopItems