import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

const ShipStatItem = ({shipStats, removeProductShipStat, inventory3, equip,
setEquip}) => {

  const [health, sethealth]=useState(require("../img/health10.png"))
  // const [fireShip, setFireShip]=useState(false)


  useEffect(()=>{
    if(shipStats.health < 110){
      sethealth(require("../img/health10.png"))
  }
    if(shipStats.health < 100){
      sethealth(require("../img/health9.png"))
  }
      if(shipStats.health < 90){
          sethealth(require("../img/health8.png"))
      }
       if(shipStats.health < 80){
        sethealth(require("../img/health7.png"))
      }
       if(shipStats.health < 70){
        sethealth(require("../img/health6.png"))
      }
        if(shipStats.health < 60){
        sethealth(require("../img/health5.png"))
      }
      if(shipStats.health < 50){
        sethealth(require("../img/health4.png"))
      }
     if(shipStats.health < 40){
        sethealth(require("../img/health3.png"))
      }
      if(shipStats.health < 30){
        sethealth(require("../img/health2.png"))
      }
     if(shipStats.health < 20){
        sethealth(require("../img/Ehealth1.png"))
      }
     if(shipStats.health < 10){
        sethealth(require("../img/Ehealth0.png"))
      }
   
  
   }, [shipStats.health])




  return (
    <>
    <div className='ship-icon'>
      <h4>
      
      <p>Ship Type: {shipStats.ship}</p>
    
      <p> Health:</p>
      <img src={health}alt="" height="300" width="300" className='health-island' />
     
      </h4>
     
      
    </div>
    
    <div className='ship-position'>
    <Link to="/my-ship"className="load-button">
      <p className='my-ship-link'>Enter Ship</p>
      </Link>
     
      {shipStats.health > 40 ? <img src={shipStats.img} alt="" height="600" width="600"/>:  <img src={shipStats.img1} alt="" height="600" width="600"/>}

      
     
    
    </div>
   
    </>
  )
}
  
export default ShipStatItem