
import React, {useState,useEffect} from 'react'

const ShipPlayerItem = ({shipStats, cannonItems, animateShip, setAnimateShip}) => {

const [health, sethealth]=useState(require("../img/health10.png"))


useEffect(()=>{
  if(shipStats.health < 110){
    sethealth(require("../img/health10.png"))
}
  if(shipStats.health < 100){
    sethealth(require("../img/health9.png"))
}
    if(shipStats.health < 90){
        sethealth(require("../img/health8.png"))
    }
     if(shipStats.health < 80){
      sethealth(require("../img/health7.png"))
    }
     if(shipStats.health < 70){
      sethealth(require("../img/health6.png"))
    }
      if(shipStats.health < 60){
      sethealth(require("../img/health5.png"))
    }
    if(shipStats.health < 50){
      sethealth(require("../img/health4.png"))
    }
   if(shipStats.health < 40){
      sethealth(require("../img/health3.png"))
    }
    if(shipStats.health < 30){
      sethealth(require("../img/health2.png"))
    }
   if(shipStats.health < 20){
      sethealth(require("../img/Ehealth1.png"))
    }
   if(shipStats.health < 10){
      sethealth(require("../img/Ehealth0.png"))
    }
 

 }, [shipStats.health])

  return (
    <>
    <div className='ship-battle'>
      <h4>
        <p>Ship Type: {shipStats.ship}</p>
        {/* <p>Health Points: {shipStats.health}</p> */}
        
    <div className='health-spacing'>
        <img src={health}alt="" style={{ width: "800px", height: "800px"}} className="healthbar"/>
        </div>
      
        <p>Cannonballs: {cannonItems.length}</p>
        </h4>
      
    
       
    </div>

<div className={animateShip}>
{shipStats.health > 40 ?<img src={shipStats.img} alt="" height="600" width="600" />: <img src={shipStats.img1} alt="" height="600" width="600" />}
</div>
</>
  )
}

export default ShipPlayerItem