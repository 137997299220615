import React,{useState,useEffect} from 'react'

const ShopItem = ({shipStats}) => {
    const [health, sethealth]=useState(require("../img/health10.png"))


    useEffect(()=>{
        if(shipStats.health < 110){
          sethealth(require("../img/health10.png"))
      }
        if(shipStats.health < 100){
          sethealth(require("../img/health9.png"))
      }
          if(shipStats.health < 90){
              sethealth(require("../img/health8.png"))
          }
           if(shipStats.health < 80){
            sethealth(require("../img/health7.png"))
          }
           if(shipStats.health < 70){
            sethealth(require("../img/health6.png"))
          }
            if(shipStats.health < 60){
            sethealth(require("../img/health5.png"))
          }
          if(shipStats.health < 50){
            sethealth(require("../img/health4.png"))
          }
         if(shipStats.health < 40){
            sethealth(require("../img/health3.png"))
          }
          if(shipStats.health < 30){
            sethealth(require("../img/health2.png"))
          }
         if(shipStats.health < 20){
            sethealth(require("../img/Ehealth1.png"))
          }
         if(shipStats.health < 10){
            sethealth(require("../img/Ehealth0.png"))
          }
       
      
       }, [shipStats.health])
  return (
    <div><li className='ship-icon1'><h4 className='headerPosition'>Ship Type: {shipStats.ship}</h4><br></br><img src={shipStats.img} alt="" width="200" height="200" className='imgPreview'/>
    {<img src={health} alt="" className='healthbar1' height="300"width="300"/>}</li></div>
  )
}

export default ShopItem