import React from 'react'

const InventoryItem = ({bag, removeProductInv, goldWallet, renderButton, ship}) => {
    
  
   const handleDelete = () =>{
    removeProductInv(bag)
   }
   const handleSell =() =>{
    removeProductInv(bag)
    const sellItems = goldWallet.map((goldWallet, index)=>{
        return <li key={index}>{goldWallet.gold += bag.price}</li>
                    })
   }
 
  return (
    <div className='game-item'>
        <p>
        {bag.weapon}
        <button onClick={handleSell}className="load-button">Sell</button>
        </p>
        
        
    </div>
  )
}

export default InventoryItem