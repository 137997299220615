
import React, {useState} from 'react'

const InventoryItem2 = ({bag, goldWallet,removeProductInv2, setEquip, equip, handleSelectedItem5,loading,
  renderButton,currentPath,removeProductShipStat}) => {
    
  const handleSell =()=>{
    removeProductInv2(bag)
    const sellItems = goldWallet.map((goldWallet, index)=>{
        return <li key={index}>{goldWallet.gold += bag.price}</li>
                    })
    removeProductShipStat(bag)
    setEquip(false)
}

const handleEquip=(e)=>{
  setEquip(true)
  handleSelectedItem5(e.target.value)
 
  

}
  return (
    <div className='game-item'>
    
   <img src={bag.img} alt="" height="60" width="60"/> <br/>{equip?<div className='ship-used'>{bag.used}</div>:null}
    { currentPath === "/shop" ?
          (<button  onClick={handleSell}className="load-button">Sell</button>)
        :null}<br/>
   
    {
    currentPath === "/shop" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    {
    currentPath === "/island-map" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    {
    currentPath === "/island-one" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    {
    currentPath === "/island-two" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    {
    currentPath === "/island-three" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    {
    currentPath === "/island-four" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
        {
    currentPath === "/island-five" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
            {/* {
    currentPath === "/extra-area5" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    } */}

    
            {
    currentPath === "/island-six" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
            {
    currentPath === "/island-seven" ?
    !equip &&(<button disabled={loading} onClick={handleEquip} value={bag._id}className="load-button"> Equip </button>):null
    }
    

     
          
        


    {/* {equip &&<p className='ship-used'>{bag.used}</p>} */}
  </div>
  )
}

export default InventoryItem2