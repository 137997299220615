import React from 'react'
import LootItem from '../MapItems/LootItem'
import '../CSS/shop.css'
import LootItemPotions from '../MapItems/LootItemPotions'

const LootList3C2 = ({loot, handleSelectedItem2, removeProductLoot, setShowLoot,potion, handleSelectedItemLootPotion,
    removePotionLoot, setShowLoot1}) => {
      const selectedLoot = loot.filter(items => items.chest.endsWith("8"));
    const lootItems = selectedLoot.map((loot) => {
       return <LootItem 
        key={loot._id}
        loot={loot}
        handleSelectedItem2={handleSelectedItem2}
        removeProductLoot={removeProductLoot}
       /> 
    })
    const selectedPotions = potion.filter(items => items.chest.endsWith("6"))
    const potionItems = selectedPotions.map((potion) => {
      return <LootItemPotions 
       key={potion._id}
       potion={potion}
       handleSelectedItemLootPotion={handleSelectedItemLootPotion}
       removePotionLoot={removePotionLoot}
      /> 
   })






 
  return (
  <>

    <div className='loot-table'>
        {lootItems}
        
   
        {/* {potionItems} */}
        <button onClick={()=>setShowLoot1(false)}className="close-button">Close</button>
        

    </div>
 
  


 </>
 
  )
}

export default LootList3C2