import React, {useState,useEffect} from 'react'
import LootListC1 from '../LootLists/LootListC1'
import LootListC2 from '../LootLists/LootListC2'
import Inventory from '../Inventory'
import '../CSS/island-background.css'
import ShipStatItem from '../MapItems/ShipStatItem'
import { Route, Routes, Link } from 'react-router-dom'
import WalletItem from '../MapItems/WalletItem'


const IslandOne = ({loot, handleSelectedItem2, inventory, removeProductInv, shipStats,
goldWallet, inventory1, removeProductInv1, removeProductLoot, removeProductInv2, inventory2, handleSelectedItem5,
removeProductShipStat, potion,  inventory3, removeProductPotion, handleSelectedItemLootPotion, ship,
handleSelectedCannon, cannons, inventory4, setEquip, equip,  removePotionLoot, hideChest ,
pirateGold,inventory5,handleQuestHolder, questHolder,removeQuestHolder,removeQuestInv,questLog,setQuestLog,
closeChat, setCloseChat, timeOutChat, setTimeoutChat, questBook, setQuestBook,setQuestBook1,questLog1, questBook1,
closeChat1,questBook2,setQuestBook2,questLog2,closeChat2,removeProductInv4,handleSelectedCannonX20,setSelectedOption,
closeChat3,recruitDialogue1, setRecruitDialogue1,showRecruit1, setShowRecruit1,myShip, setMyShip, unlock, setUnlock,waitUnlock, setWaitUnlock,
levelAnimation,setLevelAnimation,level,setLevel,levelTwo,setLevelTwo,levelThree,setLevelThree,levelFour, setLevelFour,
levelFive, setLevelFive
}) => {
    const [showLoot, setShowLoot] = useState(false)
    const [showLoot1, setShowLoot1] = useState(false)
    const [showBag, setShowBag] = useState(false)

    const [questHide, setQuestHide] = useState(false)
    const [questDialogue, setQuestDialogue] = useState(false)
    const [questDialogue1, setQuestDialogue1] = useState(false)
    const [removeEquip, setRemoveEquip]=useState()

    //where i copied state
    const [displayReward, setDisplayReward]=useState()
    const [rewardAnimation, setRewardAnimation]=useState('display-reward')
    const [questCompleted, setQuestCompleted]=useState()
    const [logBook, setLogBook] = useState(false)

    const walletItems = goldWallet.map((goldWallet, index)=>{
      return <WalletItem
        key={index}
        goldWallet={goldWallet}
        level={level}
        setLevel={setLevel}
        levelAnimation={levelAnimation}
        setLevelAnimation={setLevelAnimation}
        levelTwo={levelTwo}
        setLevelTwo={setLevelTwo}
        levelThree={levelThree}
        setLevelThree={setLevelThree}
        levelFour={levelFour}
        setLevelFour={setLevelFour}
        levelFive={levelFive}
        setLevelFive={setLevelFive}
        
      />})
    

    
    const handleRecruit =()=>{
      setRecruitDialogue1(true)
      setShowRecruit1(false)
    } 

    const handleMyShip=()=>{
      setMyShip(true)
      setRecruitDialogue1(false)
    }
    
    
    const shipStatItems = shipStats.map((shipStats, index)=>{
      return <ShipStatItem
          key={index}
          shipStats={shipStats}
          removeProductShipStat={removeProductShipStat}
          equip={equip}
          setEquip={setEquip}
          setRemoveEquip={setRemoveEquip}
          removeEquip={removeEquip}
          potion={potion}
          inventory3={inventory3}
          ship={ship}
          
        
          />
    })
   
    // let selectedLimit = questHolder.filter(items => items.chest.endsWith("2"))
    const questHolderItems = questHolder.map((quest, index)=>{
        return <li key={index} className="key"><img src={quest.img}alt="" height="50" width="50"/></li>
        
    })
    console.log("2")
    const handleBag =()=>{
      setShowBag(!showBag)
    }



const handleRemoveShip =()=>{
    setEquip(!equip)
    removeProductShipStat(shipStats)
    setSelectedOption("islandOne")
}
const handleQuest=()=>{
 
  setQuestDialogue(true)
} 
const handleQuest1=()=>{
  setQuestDialogue1(true)
}
const handleQuest2=()=>{
  setQuestLog(true)
  setQuestDialogue(false)
  setQuestDialogue1(false)
} 
const handleClose=()=>{
  setQuestDialogue(false)
 setQuestDialogue1(false)
} 
const handleHide=()=>{
  setQuestHide(true)
} 
const handleShow=()=>{
  setQuestHide(false)
} 
const handleLogBook=()=>{
  setLogBook(!logBook)
}
const handleComplete=(reward, reward1)=>{
  
  setRewardAnimation('display-reward1')
  setQuestCompleted("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  // setQuestHide(true)
  removeQuestHolder(questHolderItems)
  
  setDisplayReward(<h2 >+{reward}XP<br/>+${reward1}</h2>)
  setCloseChat(true)
  
  setTimeout(()=>{
    setDisplayReward()
    setQuestCompleted()
    setTimeoutChat(false)
   
  }, 7000)
} 
useEffect(()=>{
  if(closeChat === true)
  setTimeout(()=>{
   setWaitUnlock(true)
  }, 3000)
})
if(questLog === true){
  setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
  
}
if(closeChat === true){
  setQuestBook("Quest Completed")
}


if(questLog1 === true){
  setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
}
if(closeChat1 === true){
  setQuestBook1("Quest Completed")
}

if(questLog2 === true){
  setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
}
if(closeChat2 === true){
  setQuestBook2("Quest Completed")
}
// if close chat is true
const handleUnlock=()=>{
 
  setUnlock(true)
}







const currentPath = window.location.pathname;
  return (
   <>
    
  
    <div className='island' ><h3 className='shopGold' data-background-image='../img/island1.jpeg'>{pirateGold}</h3></div>
  
   
          <div className='log-book-icon1' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
      
      <div >
<Link to="/extra-area1">
  {hideChest?<img src={require("../img/left-arrow.png")}alt="" height="100" width="100" className='arrow-area-l'/>:null}
  </Link>
  </div>
      <h2 className='quest-completed'>{questCompleted}</h2>
      <div class={rewardAnimation}>{displayReward}</div>

    
    
    {closeChat && !unlock && waitUnlock?<div className='unlock'><h2>Island Unlocked!</h2><img src={require("../img/island-map1.png")} alt=""height="400" width="400" className='unlock-img'/>
    <img src={require("../img/unlock-padlock.png")} alt=""height="100" width="100" className='unlock-padlock'/><button onClick={handleUnlock} className="button-unlock">Close</button></div>:null}

  {/* ////// */}
{walletItems}
  {/* ////// */}

  {  equip?<div className='ship-item'>
  <h4>
  <p>Cannonballs no: {cannons.length}</p>
   <p>potion no. {inventory3.length}</p>
   <button onClick={handleRemoveShip}>Remove Ship</button>
   </h4>
    
    <div className='ship-island'>{shipStatItems}</div>
    
    </div>:null}


   { showBag? <div className='inventory-island' >
   <Inventory currentPath={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip={setEquip} equip={equip} removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}handleQuestHolder={handleQuestHolder} removeQuestInv={removeQuestInv} questLog={questLog}
        closeChat={closeChat} removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}/>
    </div> :null}
    
    <Link to="/shop" >
    <div className='sign-post'>
      <img src={require('../img/signpost.png')} alt="" height='400' width="200"/>
    </div>
    </Link>

    {!myShip ?<img src={require('../img/pirate.png')} alt="" height="300" width="300" className='pirate'/>: null}
    
  {!closeChat ?<div>{!questDialogue ?<div className='island-talk' onClick={handleQuest}><p>Talk?</p></div>:null}
    {questDialogue ?<div className='island-talk11' ><p>Arr, me hearty! I be needin some help. Can ye lend a hand?? <button onClick={handleQuest1}>Next</button></p></div>:null}

      {questDialogue1 ?<div className='island-talk21' ><p>Aye, can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!
       {!questLog ?<button onClick={handleQuest2}>Accept</button>:<button onClick={handleClose}>Close</button>}</p></div>:null}

      {questHide?<div className='island-show' onClick={handleShow}><p>Show Quest</p></div>:null}

  {!questHide ? <div>
    
  {questLog ?<div className='quest-div'>
    <button onClick={handleHide} className="hide">Hide</button>
    
    <div className='quest-log'>
    {questHolderItems}
    </div>
    <p className='quest-log1'>Quest Items: {questHolderItems.length}/3</p>

    { questHolderItems.length === 3 ?<button className='quest-log2'onClick={()=>handleComplete(160, 100)}>Complete Quest</button>:null}
    </div>: null}
    </div>: null}</div>: null}

    {timeOutChat && closeChat?<div className='island-post-quest1' onClick={handleQuest}><p>Ye be welcome, matey! I be glad to help ye out. May the seas be calm and the skies be clear for ye!</p></div>:null}

    {closeChat3  && showRecruit1 ?<div className='island-recruit' onClick={handleRecruit}><p>Recruit?</p></div>:null}

    {recruitDialogue1 ?<div className='island-talk1'><p>Ye want t' recruit me aboard ye ship sounds like a great adventure count me in!<button onClick={handleMyShip}>Recruit</button></p></div>:null}
    <div className='bag-icon' >
        <div onClick={handleBag}>
        <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'
        />
    </div>
    
    <Link to="/island-map" >
    <div className='map-icon'>
    <img src={require("../img/map.png")} alt='' height='300' width='300'
        />
    </div>
    </Link>
    
    </div>

   </>
 
  )
}

export default IslandOne