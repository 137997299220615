import React from 'react'
import PotionItem from './PotionItem'

const CartItem2 = ({cart2, handleSelectedPotionInv, goldWallet, removeProductCart2, setPurchase}) => {

    const handleBuyItem = (e) =>{
      removeProductCart2(cart2)
        handleSelectedPotionInv(e.target.value)
        const goldItems = goldWallet.map((goldWallet, index)=>{
          return <li key={index}>{goldWallet.gold -= cart2.price}</li>
                      })
                      setPurchase(<p className='shop-purchase'>Thanks for you're purchase!</p>)
                      setTimeout(()=>{
                        setPurchase()
                        }, 2000)
    }
    const handleRemove=()=>{
      removeProductCart2(cart2)
    }
  return (
    <div className='game-item'>
        
        <p><img src={cart2.img} alt="" width="50" height="50"/> ${cart2.price}<button onClick={handleRemove}>Remove</button><button onClick={handleBuyItem} value={cart2._id}>Buy</button></p>
    </div>
  )
}

export default CartItem2