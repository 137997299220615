import React, {useState, useEffect} from 'react'
import Cart from './Cart'
import ShopItems from './MapItems/ShopItems'
import './CSS/shop.css'
import Inventory from './Inventory'
import ShipItem from './MapItems/ShipItem'
import PotionItem from './MapItems/PotionItem'
import CannonballItem from './MapItems/CannonballItem'
import { Link } from 'react-router-dom'
import ShopItem from './MapItems/ShopItem'




const Shop = ({shopItems, handleSelectedItem, cart, removeProductInv, 
inventory, removeProductCart, handleSelectedItem1, goldWallet, setGoldWallet,
pirateGold, buyGold, setBuyGold, loot, inventory1, removeProductInv1, ship, handleSelectedItem3, cart1,
removeProductCart1, inventory2, handleSelectedItem4, removeProductInv2,
potion, handleSelectedPotion, cart2, handleSelectedPotionInv,  inventory3, removeProductCart2,removeProductPotion,
cannonball, handleSelectedCannonball, cart3, handleSelectedInv4, inventory4,removeProductCart3, removeProductInv4,
handleSelectedCannon, removeProductShipStat, shipStats, handleSelectedItem5,setEquip, equip, cannons,
shopMenu, setShopMenu,inventory5,closeChat1,setCloseChat1, setQuestBook1,questBook1,closeChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2,handleSelectedCannonX20,
handleSelectedQuestDeck,mapQuest, setMapQuest,hasRunOnce2, setHasRunOnce2, setCheckInv6,
}) => {
  const [loading, setLoading] = useState(false)
  const [reply, setReply] = useState(true)
  const [reply1, setReply1] = useState(false)
  const [logBook, setLogBook] = useState(false)
  const [purchase, setPurchase] = useState()
  
  
 

  
  

const shopDisplay = shopItems.map((shop)=>{
    return <ShopItems 
                key={shop._id}
                shop={shop}
                handleSelectedItem={handleSelectedItem}
               
                
                />
})

const shipItems = ship.map((ship)=>{
    return <ShipItem key={ship._id}
              ship={ship}
              handleSelectedItem3={handleSelectedItem3}
    />
})
const selectedPotion = potion.filter(items => items.chest.startsWith("s"));
const potionItems = selectedPotion.map((potion)=>{
  return <PotionItem key={potion._id}
                    potion={potion}
                    handleSelectedPotion={handleSelectedPotion}
  />
})

const cannonballItems = cannonball.map((cannonball)=>{
    return <CannonballItem 
                    key={cannonball._id}
                    cannonball={cannonball}
                    handleSelectedCannonball={handleSelectedCannonball}
    
    />
})

const shipStatItem = shipStats.map((shipStats, index)=>{


  
  return <ShopItem
            key={index}
            shipStats={shipStats}
        
        />})

  const handleRemoveShip =()=>{
    removeProductShipStat(shipStats)
    setEquip(!equip)
    // setSelectedOption("islandOne")

  }
  const handleFragment=()=>{
    handleSelectedQuestDeck("o")
    setMapQuest(false)
  }
  const handleShopMenu =()=>{
    setShopMenu(true)
    setReply(false)
  }
  const handleHowTo =()=>{
  
    setReply(false)
  }
  const handleTalk =()=>{
    setShopMenu(false)
    setReply(true)
  }

  const selectedInv1 = inventory5.filter(items => items.chest.endsWith("e"));
  useEffect(()=>{
   
    if(selectedInv1.length === 1 && !hasRunOnce2)
    {
     
      setHasRunOnce2(true);
      setCheckInv6(true)
      console.log(selectedInv1, hasRunOnce2)
      
    }
  },[hasRunOnce2, selectedInv1.length])

  const currentPath = window.location.pathname;
  // if(currentPath === "/shop"){
  //   setShopMenu(false)
  // }

  const handleNext=()=>{
    setReply1(true)
    setReply(false)
  }
  const handleClose=()=>{
    setReply(true)
    setReply1(false)
  }
  const handleLogBook=()=>{
    setLogBook(!logBook)
  }
  if(questLog === true){
    setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
    
  }
  if(closeChat === true){
    setQuestBook("Quest Completed")
  }
  if(questLog1 === true){
    setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
  }
  if(closeChat1 === true){
    setQuestBook1("Quest Completed")
  }
  
  if(questLog2 === true){
    setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
  }
  if(closeChat2 === true){
    setQuestBook2("Quest Completed")
  }
 




  
  return (
    <div className='background-shop'>
    <div className='log-book-icon2' onClick={handleLogBook}>
    <img src={require("./img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("./img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
      <h3 className='shopGold'>{pirateGold}</h3>
{mapQuest ?<img src={require("./img/map3.png") }className="fragment-shop"alt="" height="100" width={"100"} onClick={handleFragment}/>:null}
    {purchase}
    {reply?<div className='shop-speech'><p>Hey, Customer how can I help.<br/>Need supplies, ships or food?</p></div>:null}

    {!reply && !shopMenu ?<div className='shop-speech1'><p>well... I'm sure I don't have to tell an experienced pirate like yourself but<br/> if you must know i sell everything
    just don't ask how I get it! <button onClick={handleNext}>Next </button></p></div>:null}

    {reply1 && !shopMenu?<div className='shop-speech1'><p>first you'll need to buy a ship and cannonballs then load you're cannon!. Then look on you're map and look for ships to engage!! <button onClick={handleClose}>Close</button></p></div>:null}


    {reply?<div className='shop-reply' onClick={handleShopMenu}><p>What have you got in stock?</p></div>:null}
    {reply?<div className='shop-reply-how' onClick={handleHowTo}><p>How do I get a ship?</p></div>:null}
    {shopMenu?<div className='shop-talk' onClick={handleTalk}><p>Talk?</p></div>:null}
    
    {/* <img src={require("./img/Melonie-Pirate.png")} alt="" height="750" width="750" className='melonie'/> */}
    {shopMenu ?<div>
    <div className='shop-items'>
        <div>
          {/* {shopDisplay} */}
        </div>
      
        <div>
        {shipItems}
        </div>
        <div>
        {potionItems}
        </div>
        <div>
        {cannonballItems}
        </div>
      
        </div>
    
        <Cart cart={cart}cart3={cart3} ship={ship}removeProductCart={removeProductCart} handleSelectedItem1={handleSelectedItem1}
            goldWallet={goldWallet} setGoldWallet={setGoldWallet} pirateGold={pirateGold} buyGold={buyGold}
            setBuyGold={setBuyGold} cart1={cart1} removeProductCart1={removeProductCart1} handleSelectedItem4={handleSelectedItem4}
            cart2={cart2} handleSelectedPotionInv={handleSelectedPotionInv} removeProductCart2={removeProductCart2}
            handleSelectedInv4={handleSelectedInv4} removeProductCart3={removeProductCart3} setPurchase={setPurchase}
            inventory4={inventory4}/>

{  equip?<p className='ship-item1'>
<h4>
Cannonballs no: {cannons.length}<br></br>
Planks no: {inventory3.length}<br></br>
<button onClick={handleRemoveShip}>Remove Ship</button>
</h4>
   {shipStatItem}
    </p>: null}


<div className='inventory-shop'>
        <Inventory currentPath={currentPath}removeProductInv4={removeProductInv4} ship={ship}inventory={inventory} inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        loading={loading}  inventory3={inventory3} removeProductPotion={removeProductPotion}
        inventory4={inventory4}  handleSelectedCannon={handleSelectedCannon} handleSelectedItem5={handleSelectedItem5}
        setEquip={setEquip} equip={equip} shipStats={shipStats}removeProductShipStat={removeProductShipStat}
        inventory5={inventory5}handleSelectedCannonX20={handleSelectedCannonX20} 
       />
       </div>
       
  
    </div>: null}
    <Link to="/island-map" onClick={()=>setShopMenu(false)}>
    <div className='map-icon1'>
    <img src={require("./img/map.png")} alt='' height='300' width='300'
        />
    </div>
    </Link>
    </div>
  )
}

export default Shop