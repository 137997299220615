import React from 'react'
import '../CSS/shop.css'

const PotionItem = ({potion, handleSelectedPotion}) => {
    const handleBuyItem = (e) =>{
        handleSelectedPotion(e.target.value)
    }
  return (
    <div className='game-item'>
        <img src={potion.img} width="60" heigh="60" alt=""/><br/>${potion.price}<br/><button onClick={handleBuyItem} value={potion._id}className="load-button">Buy</button>
        {/* {potion.name}  */}
    </div>
  )
}

export default PotionItem