import React from 'react'
import LootItem from '../MapItems/LootItem'
import '../CSS/shop.css'
import LootItemPotions from '../MapItems/LootItemPotions'

const LootList2C2 = ({loot, handleSelectedItem2, removeProductLoot, setShowLoot,potion, handleSelectedItemLootPotion,
    removePotionLoot, setShowLoot1}) => {
    const selectedLoot = loot.filter(items => items.chest.endsWith("6"));
    const lootItems = selectedLoot.map((loot) => {
       return <LootItem 
        key={loot._id}
        loot={loot}
        handleSelectedItem2={handleSelectedItem2}
        removeProductLoot={removeProductLoot}
       /> 
    })
    const selectedPotions = potion.filter(items => items.chest.endsWith("4"))
    const potionItems = selectedPotions.map((potion) => {
      return <LootItemPotions 
       key={potion._id}
       potion={potion}
       handleSelectedItemLootPotion={handleSelectedItemLootPotion}
       removePotionLoot={removePotionLoot}
      /> 
   })
  









  return (
  <>

    <div className='loot-table'>
        {lootItems}
        
   
        {/* {potionItems} */}
        <button onClick={()=>setShowLoot1(false)}className="close-button">Close</button>
        

    </div>
    {/* <div onClick={()=>setShowLoot(!showLoot)}>
        <img src={require("./img/chest-bottom.png")} alt='treasure chest' height='500' width='500' className='chest-bottom'/>
        {showLoot? null : <img src={require("./img/chest-top.png")} alt='treasure chest' height='500' width='500' className='chest-top'/> }
    </div> */}
  


 </>
 
  )
}

export default LootList2C2