import React, {useState, useEffect} from 'react'
import LootList3C1 from '../LootLists/LootList3C1'
import LootList3C2 from '../LootLists/LootList3C2'
import Inventory from '../Inventory'
import '../CSS/island-background.css'
import ShipStatItem from '../MapItems/ShipStatItem'
import { Route, Routes, Link } from 'react-router-dom'
import WalletItem from '../MapItems/WalletItem'

const IslandFive = ({loot, handleSelectedItem2, inventory, removeProductInv, shipStats,
goldWallet, inventory1, removeProductInv1, removeProductLoot, removeProductInv2, inventory2, handleSelectedItem5,
removeProductShipStat, potion,  inventory3, removeProductPotion, handleSelectedItemLootPotion, ship,
handleSelectedCannon, cannons, inventory4, setEquip, equip,  removePotionLoot, hideChest3,
pirateGold,inventory5,closeChat1,setCloseChat1, setQuestBook1,questBook1,closeChat,setCloseChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2,removeProductInv4,handleSelectedCannonX20,
setSelectedOption,timeOutChat3, setTimeoutChat3,questBook3, setQuestBook3,closeChat3, setCloseChat3,questHolder3, setQuestHolder3,
questLog3, setQuestLog3,handleQuestHolder3,removeQuestInv, recruitDialogue, setRecruitDialogue,showRecruit, setShowRecruit,recruitIsland5, setRecruitIsland5,
myShip3, setMyShip3, unlock3,setUnlock3,setWaitUnlock3,waitUnlock3,hideChest1,levelAnimation,setLevelAnimation,level,setLevel,levelTwo,setLevelTwo,
levelThree,setLevelThree,levelFour, setLevelFour,levelFive, setLevelFive
}) => {
    const [showLoot, setShowLoot] = useState(false)
    const [showLoot1, setShowLoot1] = useState(false)
    const [showBag, setShowBag] = useState(false)
    const [logBook, setLogBook] = useState(false)

    ////
    const [questHide, setQuestHide] = useState(false)
    const [questDialogue, setQuestDialogue] = useState(false)
    const [questDialogue1, setQuestDialogue1] = useState(false)
    const [displayReward, setDisplayReward]=useState()
    const [rewardAnimation, setRewardAnimation]=useState('display-reward')
    const [questCompleted, setQuestCompleted]=useState()
    const [removeEquip, setRemoveEquip]=useState()
   
    const walletItems = goldWallet.map((goldWallet, index)=>{
      return <WalletItem
        key={index}
        goldWallet={goldWallet}
        level={level}
        setLevel={setLevel}
        levelAnimation={levelAnimation}
        setLevelAnimation={setLevelAnimation}
        levelTwo={levelTwo}
        setLevelTwo={setLevelTwo}
        levelThree={levelThree}
        setLevelThree={setLevelThree}
        levelFour={levelFour}
        setLevelFour={setLevelFour}
        levelFive={levelFive}
        setLevelFive={setLevelFive}
      />})
    const handleUnlock=()=>{
 
      setUnlock3(true)
    }
  
    useEffect(() => {
    if(closeChat3 === true)
      {setTimeout(() => {
        setShowRecruit(true);
      }, 8000);}
     if(closeChat3 === true && timeOutChat3 === false){
      {setTimeout(() => {
        setShowRecruit(true);
      }, 500);}
    }
    }, [closeChat3,timeOutChat3]);
    
const handleRecruit =()=>{
  setRecruitDialogue(true)
  setRecruitIsland5(false)
}

const handleMyShip=()=>{
  setMyShip3(true)
  setRecruitDialogue(false)
}
    const shipStatItems = shipStats.map((shipStats, index)=>{
      return <ShipStatItem
          key={index}
          shipStats={shipStats}
          removeProductShipStat={removeProductShipStat}
          equip={equip}
          setEquip={setEquip}
          setRemoveEquip={setRemoveEquip}
          removeEquip={removeEquip}
          potion={potion}
          inventory3={inventory3}
          ship={ship}
          
          

       
          
          />
    })

    const questHolderItems = questHolder3.map((quest, index)=>{
      return <li key={index} className="key"><img src={quest.img}alt="" height="50" width="50"/></li>
      
  })



    const handleBag =()=>{
      setShowBag(!showBag)
      
    }
const handleRemoveShip =()=>{
    setEquip(!equip)
    removeProductShipStat(shipStats)
    setSelectedOption("islandFive")
}
const handleQuest=()=>{
 
  setQuestDialogue(true)
} 
const handleQuest1=()=>{
  setQuestDialogue1(true)
}
const handleQuest2=()=>{
  setQuestLog3(true)
  setQuestDialogue(false)
  setQuestDialogue1(false)
} 
const handleClose=()=>{
  setQuestDialogue(false)
 setQuestDialogue1(false)
} 
const handleHide=()=>{
  setQuestHide(true)
} 
const handleShow=()=>{
  setQuestHide(false)
} 
// const handleLogBook=()=>{
//   setLogBook(!logBook)
// }
const handleComplete=(reward, reward1)=>{
  
  setRewardAnimation('display-reward1')
  setQuestCompleted("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  // setQuestHide(true)
  // removeQuestHolder(questHolderItems)
  
  setDisplayReward(<h2 >+{reward}XP<br/>+${reward1}</h2>)
  setCloseChat3(true)
  
  setTimeout(()=>{
    setDisplayReward()
    setQuestCompleted()
    setTimeoutChat3(false)
   
  }, 7000)
} 

useEffect(()=>{
  if(closeChat3 === true)
  setTimeout(()=>{
   setWaitUnlock3(true)
  }, 3000)
})
    
const handleLogBook=()=>{
  setLogBook(!logBook)
}
if(questLog === true){
  setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
  
}
if(closeChat === true){
  setQuestBook("Quest Completed")
}


if(questLog1 === true){
  setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
}
if(closeChat1 === true){
  setQuestBook1("Quest Completed")
}

if(questLog2 === true){
  setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
}
if(closeChat2 === true){
  setQuestBook2("Quest Completed")
}
const currentPath = window.location.pathname;
  return (
    <div className='island5' >
    <h3 className='shopGold'>{pirateGold}</h3>
    <div >
<Link to="/extra-area5">
  {hideChest1?<img src={require("../img/left-arrow.png")}alt="" height="100" width="100" className='arrow-area-l'/>:null}
  </Link>
  </div>
    <h2 className='quest-completed'>{questCompleted}</h2>
      <div class={rewardAnimation}>{displayReward}</div>
      <div className='log-book-icon1' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
      
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
   
{/* <div >
  
{hideChest3?<div onClick={()=>setShowLoot(!showLoot)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI7'/>
        {showLoot? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI7'/> }
    </div>:null}
{hideChest3? <div onClick={()=>setShowLoot1(!showLoot1)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI8'/>
        {showLoot1? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI8'/> }
    </div>:null}
    </div> */}



    {/* <div className='loot-oneC4'>
     {showLoot? <LootList3C1 loot={loot} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}/> :null}
    </div>
    <div className='loot-twoC4'>
     {showLoot1? <LootList3C2 loot={loot}setShowLoot1={setShowLoot1} removePotionLoot={removePotionLoot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}/> :null}
    </div> */}

    {closeChat3 && !unlock3 && waitUnlock3 ?<div className='unlock'><h2>Island Unlocked!</h2><img src={require("../img/island-map3.png")} alt=""height="300" width="300" className='unlock-map2'/><img src={require("../img/island-map2.png")} alt=""height="300" width="300" className='unlock-map3'/>
    <img src={require("../img/unlock-padlock.png")} alt=""height="100" width="100" className='unlock-padlock2'/><button onClick={handleUnlock} className="button-unlock">Close</button></div>:null}
    

 {  equip  ?<div className='ship-item'>
  
 <h4>
  <p>Cannonballs no: {cannons.length}</p>
   <p>potion no. {inventory3.length}</p>
   <button onClick={handleRemoveShip}>Remove Ship</button>
   </h4>
  
    <div className='ship-island'>{shipStatItems}</div>
    
    </div>:null}
   { showBag? <div className='inventory-island' >
   <Inventory currentPath={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip={setEquip} equip={equip} removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20} questLog3={questLog3} setQuestLog3={setQuestLog3}
        handleQuestHolder3={handleQuestHolder3}removeQuestInv={removeQuestInv}
       />
    </div> :null}
    <Link to="/shop" >
    <div className='sign-post'>
      <img src={require('../img/signpost.png')} alt="" height='400' width="200"/>
    </div>
    </Link>
    {walletItems}


    {!myShip3 ?<img src={require('../img/pirate-lady-drink.png')} alt="" height="300" width="300" className='pirate'/>:null}
    
    {!closeChat3 ?<div>{!questDialogue ?<div className='island-talk' onClick={handleQuest}><p>Talk?</p></div>:null}
      {questDialogue ?<div className='island-talk1' ><p>That damn ship stole my loot! if you can retrieve it I'll give ye a portion of the spoils?<button onClick={handleQuest1}>Next</button></p></div>:null}
  
        {questDialogue1 ?<div className='island-talk2' ><p>there's a brig down south if you can retrieve me bag of jewels I'll reward ya
          <br/>{!questLog3 ?<button onClick={handleQuest2}>Accept</button>:<button onClick={handleClose}>Close</button>}</p></div>:null}
  
        {questHide?<div className='island-show' onClick={handleShow}><p>Show Quest</p></div>:null}
  
    {!questHide ? <div>
      
    {questLog3 ?<div className='quest-div'>
      <button onClick={handleHide} className="hide">Hide</button>
      
      <div className='quest-log'>
      {questHolderItems}
      </div>
      <p className='quest-log1'>Quest Items: {questHolderItems.length}/3</p>
  
      { questHolderItems.length === 3 ?<button className='quest-log2'onClick={()=>handleComplete(250, 200)}>Complete Quest</button>:null}
      </div>: null}
      </div>: null}</div>: null}
  
      {timeOutChat3 && closeChat3?<div className='island-post-quest1' onClick={handleQuest}><p>Ye be welcome, matey! I be glad to help ye out. May the seas be calm and the skies be clear for ye!</p></div>:null}

      
              
    
          {closeChat3 && showRecruit && recruitIsland5 ?<div className='island-recruit' onClick={handleRecruit}><p>Recruit?</p></div>:null}
          {recruitDialogue ?<div className='island-talk1'><p>Ye want t' recruit me aboard ye ship sounds like a great adventure count me in!<button onClick={handleMyShip}>Recruit</button></p></div>:null}
    <div className='bag-icon' >
        <div onClick={handleBag}>
        <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'/>
    </div>

    <Link to="/island-map" >
    <div className='map-icon'>
    <img src={require("../img/map.png")} alt='' height='300' width='300'
        />
    </div>
    </Link>


    </div>

    </div>
  )
}

export default IslandFive