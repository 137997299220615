import React, {useState} from 'react'

const CannonItem = ({cannons, removeProductCannon,enemyShip,
setDamage, shipStats, setEnemyDamage,disarm, setDisarm, animateShip, setAnimateShip,
animateEnemy,setAnimateEnemy,cannonClass, setCannonClass,cannonAnmiate,
cannonEnemy, setCannonEnemy}) => {
  



    const handleAttack=()=>{
     setCannonClass("cannonAnimate1")
      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
     }
     const randomValue = getRandomInt(3)
     console.log(randomValue)
    let attack; 

      setDamage(<h1 className='damage-cannon'>Damage: {cannons.damage}</h1>)
       
    
        enemyShip.map((enemyShip)=>{
          setDisarm(true)
          setAnimateShip("ship-animate1")
        

          setTimeout(()=>{
          setAnimateShip("ship-position1")
          
         
          }, 500)


          setTimeout(()=>{
            setDisarm(false)
           
          }, 3500)
          
            return (
              enemyShip.health -= cannons.damage
               
              )
              
        })
        
        removeProductCannon(cannons)
        setTimeout(() => {
        shipStats.map((shipStats)=>{
          return (
          enemyShip.map((enemyShip)=>{
              
            if(randomValue < 1){
              attack = enemyShip.attack
              
            }
              else if(randomValue < 2){
                  attack = enemyShip.attack1
              }

              else if(randomValue < 3){
                attack = enemyShip.attack2
            }
            else if(randomValue < 4){
              attack = enemyShip.attack3
          }
          if(enemyShip.health <= 0){
            attack = null
        }
              
            return (
              shipStats.health -= attack
              
               )
          })
          
            )
      })
            setEnemyDamage(<h1 className='damage-enemy'>Damage: {attack}</h1>)
            setDamage()
            setAnimateEnemy("brig-enemy-animate1")
            setCannonEnemy("cannonEnemy1")
          setTimeout(() => {
            setEnemyDamage()
        
          },2000)
          setTimeout(() => {
      
            setAnimateEnemy("brig-enemy")
              setCannonEnemy("cannonEnemy")
          },500)
          setCannonClass("cannonAnimate")
        }, 2000);
        
    }

 
  return (
    <>
    <div  className='inline-cannonballs' onClick={handleAttack}><img src={cannons.img1} alt="" height="40" width="40"/></div>
    

    
     </>

  )
}

export default CannonItem