
import React,{useState, useEffect} from 'react'
import Inventory from '../Inventory'
import ShipEnemyItem from '../MapItems/ShipEnemyItem'
import ShipPlayerItem from '../MapItems/ShipPlayerItem'
import CannonItem from '../MapItems/CannonItem'
import { Link,useNavigate } from 'react-router-dom'


const EnemyBattle2 = ({shipStats,inventory3, ship, inventory,inventory2, goldWallet,removeProductInv, inventory1,
loot, removeProductInv1, removeProductInv2, removeProductPotion, loading, renderButton, removeProductShipStat,
 inventory4, cannons, handleSelectedCannon, removeProductCannon, enemyShip, board2,
setBoard2,pirateGold,animateShip, setAnimateShip,animateEnemy,setAnimateEnemy,
inventory5,closeChat1,setCloseChat1, setQuestBook1,questBook1,closeChat,setCloseChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2,removeProductInv4,
handleSelectedCannonX20, setSunkShip2}) => {

const navigate = useNavigate()
const [damage, setDamage]= useState()
const [enemyDamage, setEnemyDamage]= useState()
const [disarm, setDisarm] = useState(false)
const [logBook, setLogBook] = useState(false)
const [cannonEnemy, setCannonEnemy] = useState("cannonEnemy")
const [cannonClass, setCannonClass] = useState("cannonAnimate")
const [showHeal, setShowHeal] = useState(<h2></h2>)
const [victory, setVictory] = useState()



const cannonItems = cannons.map((cannons, index)=>{
  return <CannonItem 
          key={index}
          cannons={cannons}
          shipStats={shipStats}
          removeProductCannon={removeProductCannon}
          enemyShip={enemyShip}
          setDamage={setDamage}
          setEnemyDamage={setEnemyDamage}
          disarm={disarm}
          setDisarm={setDisarm}
          setAnimateShip={setAnimateShip}
          animateShip={animateShip}
          animateEnemy={animateEnemy}
          setAnimateEnemy={setAnimateEnemy}
          setCannonClass={setCannonClass}
          cannonClass={cannonClass}
          cannonEnemy={cannonEnemy}
          setCannonEnemy={setCannonEnemy}
    
       
  />
})

const shipStatsItem = shipStats.map((shipStats)=>{
        return <ShipPlayerItem 
                    key={shipStats._id}
                    shipStats={shipStats}
                    ship={ship}
                    removeProductShipStat={removeProductShipStat}
                    cannonItems={cannonItems}
                    setAnimateShip={setAnimateShip}
                    animateShip={animateShip}
                />
            })
const shipItem = enemyShip.map((enemyShip)=>{
    return <ShipEnemyItem
            key={enemyShip._id}
            enemyShip={enemyShip}
            shipStats={shipStats}
            setAnimateShip={setAnimateShip}
            animateShip={animateShip}
           
    />
})

useEffect(()=>{
  enemyShip.map((enemyShip)=>{
    
    if(enemyShip.health <= 0){
        setBoard2(true)
       

        enemyShip.health = 0;
        setCannonEnemy("cannonEnemy") 
        setAnimateEnemy("brig-enemy")  
        setEnemyDamage(null)
        setVictory("Victory!")
    }
  })
}, [cannonEnemy, enemyShip.health,animateEnemy])


useEffect(()=>{
  if(shipStats.health <= 0){
    setSunkShip2(false)
  }
},[setSunkShip2])
   
  


if(shipStats.length === 0){
  navigate('/island-map')
  }

  const boardFalse =()=>{
    setBoard2(false)
    setSunkShip2(false)
    enemyShip.map((enemyShip)=>{
      return enemyShip.health = 100
    })
  }
  const mapFalse =()=>{
    setBoard2(false)
    enemyShip.map((enemyShip)=>{
      return enemyShip.health = 100
    })
    // shipStats.map((shipStats)=>{
    //   return shipStats.health = 100
    // })
  }
  useEffect(()=>{
    shipStats.map((shipStats)=>{
        if(shipStats.health <= 0){
          shipStats.health = 0
          navigate("/defeat")
          enemyShip.map((enemyShip)=>{
            return enemyShip.health = 100
          })
        }
    })
  })
 
  const handleLogBook=()=>{
    setLogBook(!logBook)
  }
  if(questLog === true){
    setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
    
  }
  if(closeChat === true){
    setQuestBook("Quest Completed")
  }
  if(questLog1 === true){
    setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
  }
  if(closeChat1 === true){
    setQuestBook1("Quest Completed")
  }
  
  if(questLog2 === true){
    setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
  }
  if(closeChat2 === true){
    setQuestBook2("Quest Completed")
  }
  const currentPath = window.location.pathname;
  return (
    <>
    
    <div className='enemy-background'>
    <h3 className='shopGold'>{pirateGold}</h3>
    <h1 className='victory'>{victory}</h1>
    {showHeal}
    <div className='log-book-icon2' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" onClick={mapFalse}className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
   
    {board2?<div className='board-prompt'>
      <h3>Enemy Defeated</h3>
      <h4>Board Ship?</h4>
      <>
      <Link to="/ship-deck" className='board-link'onClick={boardFalse}><button>Yes</button></Link>
      <br/><br/>
      <Link to="/island-map" className='board-link'onClick={boardFalse}><button>No</button></Link>
      </>
    </div>:null}
    {/* <Link to="/island-map" onClick={mapFalse} >
    <div className='map-battle'>
    <img src={require("../img/map.png")} alt='' height='150' width='150'/>
    </div>
    </Link> */}
    <div className='enemyPlayer' >
        {shipItem.splice(0,1)}
    
    
    </div>
   {damage}
   {enemyDamage}
    <div className={cannonClass}><img src={require("../img/cannonball.png")} alt="" height="70"width="70"/></div>
    <div className={cannonEnemy}><img src={require("../img/cannonball.png")} alt="" height="70"width="70"/></div>

    {enemyShip.map((enemyShip)=>(enemyShip.health > 40 ?<img src={require("../img/galleonRevamp.png")} alt="" height="500" width="500" className={animateEnemy}/>: <img src={require("../img/fireGalleonRevamp.png")} alt="" height="500" width="500" className={animateEnemy}/>))}
    <div className='player'>

    <div className='shipDiv'>
        {shipStatsItem}
        
    </div>
    <div className='cannon'>
      
    {!disarm ? cannonItems: <h3 className='incoming'>   Incoming Attack!</h3>}
    </div>
   
    <div className='inventory-battle'>
    <Inventory currentPath={currentPath}ship={ship}inventory={inventory} inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        loading={loading} renderButton={renderButton} inventory3={inventory3} removeProductPotion={removeProductPotion}
         inventory4={inventory4} handleSelectedCannon={handleSelectedCannon} shipStats={shipStats}
         inventory5={inventory5}showHeal={showHeal}setShowHeal={setShowHeal}removeProductInv4={removeProductInv4} handleSelectedCannonX20={handleSelectedCannonX20}
       />
       </div>
    </div>
    </div>
    </>
  )
}

export default EnemyBattle2