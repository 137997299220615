
import React,{useState,useEffect} from 'react'

const ShipEnemyItem = ({enemyShip
}) => {

  const [health, sethealth]=useState(require("../img/Ehealth10.png"))


  useEffect(()=>{
  
      if(enemyShip.health < 100){
          sethealth(require("../img/Ehealth9.png"))
      }
       if(enemyShip.health < 90){
        sethealth(require("../img/Ehealth8.png"))
      }
       if(enemyShip.health < 80){
        sethealth(require("../img/Ehealth7.png"))
      }
        if(enemyShip.health < 70){
        sethealth(require("../img/Ehealth6.png"))
      }
      if(enemyShip.health < 60){
        sethealth(require("../img/Ehealth5.png"))
      }
     if(enemyShip.health < 50){
        sethealth(require("../img/Ehealth4.png"))
      }
      if(enemyShip.health < 40){
        sethealth(require("../img/Ehealth3.png"))
      }
     if(enemyShip.health < 30){
        sethealth(require("../img/Ehealth2.png"))
      }
     if(enemyShip.health < 20){
        sethealth(require("../img/Ehealth1.png"))
      }
       if(enemyShip.health < 10){
        sethealth(require("../img/Ehealth0.png"))
      }
  
   }, [enemyShip.health])
  


  return (
    <div>
      <h4>
        <p>Ship Type: {enemyShip.ship}</p>
       
        <img src={health}alt="" style={{ width: "600px", height: "600px"}} className="enemy-healthbar"/>
        </h4>
       
        
        
        
    </div>
  )
}

export default ShipEnemyItem