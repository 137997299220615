import React from 'react'

const LootItemPotions = ({potion, handleSelectedItemLootPotion,removePotionLoot}) => {

    const handleTransfer = (e) =>{
        handleSelectedItemLootPotion(e.target.value)
        removePotionLoot(potion)
       
     }

     
  return (
    <div className='game-item1'>
        <img src={potion.img} alt="" height="50" width="50"/><button onClick={handleTransfer} value={potion._id}className="load-button">Loot</button>
    </div>
  )
}

export default LootItemPotions