import React, {useState,useEffect} from 'react'
import LootListC1 from '../LootLists/LootListC1'
import LootListC2 from '../LootLists/LootListC2'
import Inventory from '../Inventory'
import '../CSS/island-background.css'
import ShipStatItem from '../MapItems/ShipStatItem'
import { Route, Routes, Link } from 'react-router-dom'
import WalletItem from '../MapItems/WalletItem'


const ExtraArea1 = ({loot, handleSelectedItem2, inventory, removeProductInv, shipStats,
goldWallet, inventory1, removeProductInv1, removeProductLoot, removeProductInv2, inventory2, handleSelectedItem5,
removeProductShipStat, potion,  inventory3, removeProductPotion, handleSelectedItemLootPotion, ship,
handleSelectedCannon, cannons, inventory4, setEquip, equip,  removePotionLoot, hideChest ,
pirateGold,inventory5,handleQuestHolder, questHolder,removeQuestHolder,removeQuestInv,questLog,setQuestLog,
closeChat, setCloseChat, timeOutChat, setTimeoutChat, questBook, setQuestBook,setQuestBook1,questLog1, questBook1,
closeChat1,questBook2,setQuestBook2,questLog2,closeChat2,removeProductInv4,handleSelectedCannonX20,setSelectedOption,
closeChat3,recruitDialogue1, setRecruitDialogue1,showRecruit1, setShowRecruit1,myShip, setMyShip, unlock, setUnlock,waitUnlock,
 setWaitUnlock,levelAnimation,setLevelAnimation,level,setLevel,levelTwo,setLevelTwo,
 levelThree,setLevelThree,levelFour, setLevelFour,levelFive, setLevelFive
}) => {
    const [showLoot, setShowLoot] = useState(false)
    const [showLoot1, setShowLoot1] = useState(false)
    const [showBag, setShowBag] = useState(false)

    const [questHide, setQuestHide] = useState(false)
    const [questDialogue, setQuestDialogue] = useState(false)
    const [questDialogue1, setQuestDialogue1] = useState(false)
    const [removeEquip, setRemoveEquip]=useState()

    //where i copied state
    const [displayReward, setDisplayReward]=useState()
    const [rewardAnimation, setRewardAnimation]=useState('display-reward')
    const [questCompleted, setQuestCompleted]=useState()
    const [logBook, setLogBook] = useState(false)


    

    
   
    
    const walletItems = goldWallet.map((goldWallet, index)=>{
      return <WalletItem
        key={index}
        goldWallet={goldWallet}
        level={level}
        setLevel={setLevel}
        levelAnimation={levelAnimation}
        setLevelAnimation={setLevelAnimation}
        levelTwo={levelTwo}
        setLevelTwo={setLevelTwo}
        levelThree={levelThree}
        setLevelThree={setLevelThree}
        levelFour={levelFour}
        setLevelFour={setLevelFour}
        levelFive={levelFive}
        setLevelFive={setLevelFive}
      />})
    const shipStatItems = shipStats.map((shipStats, index)=>{
      return <ShipStatItem
          key={index}
          shipStats={shipStats}
          removeProductShipStat={removeProductShipStat}
          equip={equip}
          setEquip={setEquip}
          setRemoveEquip={setRemoveEquip}
          removeEquip={removeEquip}
          potion={potion}
          inventory3={inventory3}
          ship={ship}
          
        
          />
    })
    // let selectedLimit = questHolder.filter(items => items.chest.endsWith("2"))
    const questHolderItems = questHolder.map((quest, index)=>{
        return <li key={index} className="key"><img src={quest.img}alt="" height="50" width="50"/></li>
        
    })
    console.log("2")
    const handleBag =()=>{
      setShowBag(!showBag)
    }



const handleRemoveShip =()=>{
    setEquip(!equip)
    removeProductShipStat(shipStats)
    setSelectedOption("islandOne")
}
const handleQuest=()=>{
 
  setQuestDialogue(true)
} 
const handleQuest1=()=>{
  setQuestDialogue1(true)
}
const handleQuest2=()=>{
  setQuestLog(true)
  setQuestDialogue(false)
  setQuestDialogue1(false)
} 
const handleClose=()=>{
  setQuestDialogue(false)
 setQuestDialogue1(false)
} 
const handleHide=()=>{
  setQuestHide(true)
} 
const handleShow=()=>{
  setQuestHide(false)
} 
const handleLogBook=()=>{
  setLogBook(!logBook)
}
const handleComplete=(reward, reward1)=>{
  
  setRewardAnimation('display-reward1')
  setQuestCompleted("Quest Completed!")
  const log =goldWallet.map((goldWallet, index)=>{
      let myXp =goldWallet.xp += reward
      let myGold =goldWallet.gold += reward1
      const myReward = myXp + myGold
     
      return <li key={index}>{myReward}</li>
  })
  // setQuestHide(true)
  removeQuestHolder(questHolderItems)
  
  setDisplayReward(<h2 >+{reward}XP<br/>+${reward1}</h2>)
  setCloseChat(true)
  
  setTimeout(()=>{
    setDisplayReward()
    setQuestCompleted()
    setTimeoutChat(false)
   
  }, 7000)
} 
useEffect(()=>{
  if(closeChat === true)
  setTimeout(()=>{
   setWaitUnlock(true)
  }, 3000)
})
if(questLog === true){
  setQuestBook(" can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
  
}
if(closeChat === true){
  setQuestBook("Quest Completed")
}


if(questLog1 === true){
  setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
}
if(closeChat1 === true){
  setQuestBook1("Quest Completed")
}

if(questLog2 === true){
  setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
}
if(closeChat2 === true){
  setQuestBook2("Quest Completed")
}
// if close chat is true
const handleUnlock=()=>{
 
  setUnlock(true)
}
const currentPath = window.location.pathname;
  return (
    <div className='island' >
          <div className='log-book-icon1' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
      <h3 className='shopGold'>{pirateGold}</h3>
  <div >
<Link to="/island-one">
  {hideChest?<img src={require("../img/left-arrow.png")}alt="" height="100" width="100" className='arrow-area-r'/>:null}
  </Link>
  </div>
      <h2 className='quest-completed'>{questCompleted}</h2>
      <div class={rewardAnimation}>{displayReward}</div>
   <div onClick={()=>setShowLoot(!showLoot)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI1'/>
        {showLoot? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI1'/> }
    </div> 
    




   <div onClick={()=>setShowLoot1(!showLoot1)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottomI2'/>
        {showLoot1? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-topI2'/> }
    </div>
    
    
    {closeChat && !unlock && waitUnlock?<div className='unlock'><h2>Island Unlocked!</h2><img src={require("../img/island-map1.png")} alt=""height="400" width="400" className='unlock-img'/>
    <img src={require("../img/unlock-padlock.png")} alt=""height="100" width="100" className='unlock-padlock'/><button onClick={handleUnlock} className="button-unlock">Close</button></div>:null}

    <div className='loot-oneC1'>
     {showLoot? <LootListC1 loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
     removeProductPotion={removeProductPotion} removePotionLoot={removePotionLoot} /> :null}
    </div>

    <div className='loot-twoC1'>
     {showLoot1? <LootListC2 loot={loot} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} setShowLoot1={setShowLoot1}potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
     removeProductPotion={removeProductPotion} removePotionLoot={removePotionLoot} /> :null}
    </div>
    

 


   { showBag? <div className='inventory-island' >
   <Inventory currentPath={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip={setEquip} equip={equip} removeEquip={removeEquip} setRemoveEquip={setRemoveEquip} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}handleQuestHolder={handleQuestHolder} removeQuestInv={removeQuestInv} questLog={questLog}
        closeChat={closeChat} removeProductInv4={removeProductInv4}handleSelectedCannonX20={handleSelectedCannonX20}/>
    </div> :null}
    

    {walletItems}
    <div className='bag-icon' >
        <div onClick={handleBag}>
        <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'
        />
    </div>
    
    <Link to="/island-map" >
    <div className='map-icon'>
    <img src={require("../img/map.png")} alt='' height='300' width='300'
        />
    </div>
    </Link>
    
    </div>

    </div>
  )
}

export default ExtraArea1