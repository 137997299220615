import React from 'react'
import '../CSS/shop.css'

const LootItem = ({loot, handleSelectedItem2, removeProductLoot}) => {

 const handleTransfer = (e) =>{
    handleSelectedItem2(e.target.value)
    removeProductLoot(loot)
 }
  return (
    <div className='game-item1'><img src={loot.img} alt="" height="40" width="40"/><button onClick={handleTransfer} value={loot._id}className="load-button">Loot</button></div>
  )
}

export default LootItem