import React,{useEffect, useState} from 'react'

const WalletItem = ({goldWallet, level, setLevel, levelAnimation, setLevelAnimation,
  levelTwo, setLevelTwo,levelThree,setLevelThree,levelFour, setLevelFour,levelFive, setLevelFive}) => {


    useEffect(() => {
        let updatedLevel = 0;
        if (goldWallet.level >= updatedLevel) {
          updatedLevel = goldWallet.level;
        }
        if (updatedLevel >= 2 && updatedLevel < 3 && !levelTwo) {
          setLevel('level 2');
          setLevelAnimation('levelAnimation')
          

          setTimeout(() => {
            setLevel()
            setLevelAnimation()
            setLevelTwo(true)
          },3000)
        } else if (updatedLevel >= 3 && updatedLevel < 4 && !levelThree) {
          setLevel('level 3');
          setLevelAnimation('levelAnimation')

          setTimeout(() => {
            setLevel()
            setLevelAnimation()
            setLevelThree(true)
          },3000)
        } else if (updatedLevel >= 4 && updatedLevel < 5 && !levelFour) {
          setLevel('level 4');
          setLevelAnimation('levelAnimation')

          setTimeout(() => {
            setLevel()
            setLevelAnimation()
            setLevelFour(true)
          },3000)

        } else if (updatedLevel >= 5 && updatedLevel < 6 && !levelFive) {
          setLevel('level 5');
          setLevelAnimation('levelAnimation')

          setTimeout(() => {
            setLevel()
            setLevelAnimation()
            setLevelFive(true)
          },3000)

        }

     
      }, [goldWallet.level, setLevel, level]);
      const [health, sethealth]=useState(require("../img/xp0.png"))
      const [xpBar, setXpBar]=useState(`${goldWallet.xp}/0`)
      

      useEffect(()=>{
        let totalXP;
        if(goldWallet.level === 1)
        {
        totalXP = 400;
        setXpBar(`${goldWallet.xp}/${totalXP}XP Level 2`)
      }
        else if(goldWallet.level === 2)
        {
          totalXP = 500;
          setXpBar(`${goldWallet.xp}/${totalXP}XP Level 3`)
        }
        else if(goldWallet.level === 3)
        {
          totalXP = 600;
          setXpBar(`${goldWallet.xp}/${totalXP}XP Level 4`)
        }
        else if(goldWallet.level === 4)
        {
          totalXP = 700;
           setXpBar(`${goldWallet.xp}/${totalXP}XP Level 5`)
        }
        else if(goldWallet.level === 5)
        {
          totalXP = 800;
          setXpBar(`${goldWallet.xp}/${totalXP}XP to Level 6`)
        }
        const xpRatio = goldWallet.xp / totalXP;
      
        if (xpRatio >= 1.0) {
          sethealth(require("../img/xp10.png"));
        } else if (xpRatio >= 0.9) {
          sethealth(require("../img/xp9.png"));
        } else if (xpRatio >= 0.8) {
          sethealth(require("../img/xp8.png"));
        } else if (xpRatio >= 0.7) {
          sethealth(require("../img/xp7.png"));
        } else if (xpRatio >= 0.6) {
          sethealth(require("../img/xp6.png"));
        } else if (xpRatio >= 0.5) {
          sethealth(require("../img/xp5.png"));
        } else if (xpRatio >= 0.4) {
          sethealth(require("../img/xp4.png"));
        } else if (xpRatio >= 0.3) {
          sethealth(require("../img/xp3.png"));
        } else if (xpRatio >= 0.2) {
          sethealth(require("../img/xp2.png"));
        } else if (xpRatio >= 0.1) {
          sethealth(require("../img/xp1.png"));
        } else {
          sethealth(require("../img/xp0.png"));
        }
      }, [goldWallet.xp])
      


      
      
  return (
   
    <div>
      <h1 className={levelAnimation}>{level}</h1>
      <div className='health-spacing'>
        <img src={health} alt="" style={{ width: "800px", height: "800px"}} className="xp-bar"/>
        <h3 className='xpBar'>{xpBar}</h3>
        </div>
    
    </div>
  )
}

export default WalletItem