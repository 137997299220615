import React,{useEffect} from 'react'

const CartItem1 = ({cart1,removeProductCart1, handleSelectedItem4, goldWallet,setPurchase}) => {
    const handleBuyItem = (e) =>{

      
      
        handleSelectedItem4(e.target.value)
        removeProductCart1(cart1)
        setPurchase(<p className='shop-purchase'>Thanks for you're purchase!</p>)
        setTimeout(()=>{
          setPurchase()
          }, 2000)
        const goldItems = goldWallet.map((goldWallet, index)=>{
            return <li key={index}>{goldWallet.gold -= cart1.price}</li>
                        })

    }
    
  return (
    <div className='game-item'>
    <p><img src={cart1.img} alt="" height="60" width="60"/><button onClick={()=>removeProductCart1(cart1)}>Remove</button><button  onClick={handleBuyItem}value={cart1._id}>Buy</button></p>
 
    
</div>
  )
}

export default CartItem1