import React,{useState, useEffect} from 'react'
import '../CSS/island-background.css'
import { Link} from 'react-router-dom'
import LootListD3 from '../LootLists/LootListD3'
import LootListD4 from '../LootLists/LootListD4'
import Inventory from '../Inventory'
const ShipDeck1 = ({hideChest1, setHideChest1, setRevealPrompt2, revealPrompt2, shipStats, loot, removePotionLoot,
  handleSelectedItem2,potion, removeProductPotion,handleSelectedItemLootPotion, removeProductLoot, pirateGold, inventory,
cannons, inventory3, goldWallet, handleSelectedItem5, inventory2,removeProductInv,
inventory1, removeProductInv1, removeProductInv2, inventory4, handleSelectedCannon,
removeProductShipStat, removeEquip2, setRemoveEquip2, equip2, setEquip2, setRevealPrompt1, revealPrompt1, questItems,
handleSelectedQuestItems,removeQuestLoot,inventory5,closeChat1,setCloseChat1, setQuestBook1,questBook1,closeChat,
setCloseChat, setQuestBook,
questBook,questLog1,questLog,questBook2,setQuestBook2,questLog2, closeChat2}) => {
  const [showLoot, setShowLoot] = useState(false)
  const [showLoot1, setShowLoot1] = useState(false)
  const [nightDay, setNightDay] = useState()
  const [showBag, setShowBag] = useState(false)
  const [logBook, setLogBook] = useState(false)
 

  useEffect(()=>{
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
   }
   const randomValue = getRandomInt(2)
      if(randomValue === 1){
          setNightDay('ship-deck')
      }
      else if(randomValue === 0){
          setNightDay('ship-deck-night')
      }
  },[])
  const handleHideChest=async()=>{
    await setHideChest1(true)
    if(hideChest1 === false)
        {
          setRevealPrompt1(<div className='reveal-prompt'>Hidden Area of IslandThree revealed!<br/><br/><Link to="/island-two" className='travel'>Travel Now!</Link></div>)
      }
  }
  const handleBag =()=>{
    setShowBag(!showBag)
  }
  const handleLogBook=()=>{
    setLogBook(!logBook)
  }
  if(questLog === true){
    setQuestBook("can ye find me three golden keys that a galleon stole from me north west of here? There be some coin in it for ye, matey!")
    
  }
  if(closeChat === true){
    setQuestBook("Quest Completed")
  }
  if(questLog1 === true){
    setQuestBook1(" I be needin ye to find me three golden keys on a brig just leavin here! Can ye lend a hand?")
  }
  if(closeChat1 === true){
    setQuestBook1("Quest Completed")
  }
  
  if(questLog2 === true){
    setQuestBook2("Aye, can ye find me three golden keys that a sloop has north east of here? And is it really thievin if they stole it first?")
  }
  if(closeChat2 === true){
    setQuestBook2("Quest Completed")
  }
  const currentPath = window.location.pathname;
  return (
    <div className={nightDay}>
    <div className='log-book-icon3' onClick={handleLogBook}>
    <img src={require("../img/log-book.png")} alt=""
      height="350" width="350"/>
    </div>
    
    {logBook?<div className='log-book1'><pre><h2 className='log-header'>Quest Log</h2></pre><p className='log-text'>{questBook}</p><br/><p className='log-text'>{questBook1}</p><br/><p className='log-text'>{questBook2}</p>
    <Link to="/island-map" className='map-button'><img src={require("../img/map.png")} alt="" height="80" width="80" /></Link></div>:null}
      <h3 className='shopGold'>{pirateGold}</h3>
      <h2>{revealPrompt1}</h2>

{/* <div onClick={handleHideChest}><img src={require('../img/map.png')} alt="" className='treasure-map'/></div> */}
<div className='bag-icon2'>
    <div onClick={handleBag} >
    <img src={require("../img/bag.png")} alt='treasure chest' height='500' width='500'/>
    </div>
    </div>
<div onClick={()=>setShowLoot(!showLoot)}>
        <img src={require("../img/green-bottom.png")} alt='treasure chest'  className='chest-bottom0DR'/>
        {showLoot? null : <img src={require("../img/green-top.png")} alt='treasure chest'  className='chest-top0DR1'/> }
    </div> 

  <div onClick={()=>setShowLoot1(!showLoot1)}>
        <img src={require("../img/chest-bottom1.png")} alt='treasure chest'  className='chest-bottom0DL'/>
        {showLoot1? null : <img src={require("../img/chest-top.png")} alt='treasure chest'  className='chest-top0DL'/> }
    </div> 


    <div className='loot-oneD1'>
     {showLoot? <LootListD3 handleSelectedQuestItems={handleSelectedQuestItems} loot={loot} questItems={questItems} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
     removeProductPotion={removeProductPotion} removePotionLoot={removePotionLoot} removeQuestLoot={removeQuestLoot}/> :null}
    </div>

    <div className='loot-twoD2'>
     {showLoot1? <LootListD4  handleSelectedQuestItems={handleSelectedQuestItems} loot={loot} questItems={questItems} handleSelectedItem2={handleSelectedItem2} removeProductLoot={removeProductLoot}
     setShowLoot={setShowLoot} showLoot={showLoot} setShowLoot1={setShowLoot1}potion={potion} handleSelectedItemLootPotion={handleSelectedItemLootPotion}
     removeProductPotion={removeProductPotion} removePotionLoot={removePotionLoot} removeQuestLoot={removeQuestLoot}/> :null}
    </div>

<Link to="/island-map" >
    <div className='map-icon2'><img src={require('../img/map.png')} alt="" height='300' width='300'/></div>
</Link>
{showBag?<div className='inventory-shipDeck' >
    <Inventory currentPath ={currentPath}inventory={inventory}  cannons={cannons} inventory3={inventory3} setShowBag={setShowBag}handleSelectedItem5={handleSelectedItem5}
     inventory2={inventory2}removeProductInv={removeProductInv} goldWallet={goldWallet}
        loot={loot} inventory1={inventory1} removeProductInv1={removeProductInv1} removeProductInv2={removeProductInv2}
        setEquip2={setEquip2} equip2={equip2} removeEquip2={removeEquip2} setRemoveEquip2={setRemoveEquip2} removeProductShipStat={removeProductShipStat} 
        shipStats={shipStats} removeProductPotion={removeProductPotion} handleSelectedCannon={handleSelectedCannon} inventory4={inventory4}
        inventory5={inventory5}
       />
       </div>: null}
    </div>

  )
}

export default ShipDeck1