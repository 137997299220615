import React from 'react'
import LootItem from '../MapItems/LootItem'
import '../CSS/shop.css'
import LootItemPotions from '../MapItems/LootItemPotions'
import LootQuestItem from '../MapItems/LootQuestItem'

const LootListD6 = ({loot, handleSelectedItem2, removeProductLoot, setShowLoot1,potion, handleSelectedItemLootPotion,
    removePotionLoot,questItems,handleSelectedQuestItems,removeQuestLoot}) => {
    const selectedLoot = loot.filter(items => items.chest.endsWith("14"));
 

    const lootItems = selectedLoot.map((loot) => {
       return <LootItem 
        key={loot._id}
        loot={loot}
        handleSelectedItem2={handleSelectedItem2}
        removeProductLoot={removeProductLoot}
       /> 
    })


    const selectedPotions = potion.filter(items => items.chest.endsWith("14"))
    const potionItems = selectedPotions.map((potion) => {
      return <LootItemPotions 
       key={potion._id}
       potion={potion}
       handleSelectedItemLootPotion={handleSelectedItemLootPotion}
       removePotionLoot={removePotionLoot}
      /> 
   })

   const selectedQuestItems = questItems.filter(items => items.chest.endsWith("6"))
   const questItemsCollection = selectedQuestItems.map((quest) => {
     return <LootQuestItem 
      key={quest._id}
      quest={quest}
      handleSelectedQuestItems={handleSelectedQuestItems}
      removeQuestLoot={removeQuestLoot}
     /> 
  })
   










  return (
  <>

    <div className='loot-table'>
        {lootItems}
        {/* {potionItems} */}
        {questItemsCollection}
        <button onClick={()=>setShowLoot1(false)}className="close-button">Close</button>
        
    </div>


  


 </>
 
  )
}

export default LootListD6