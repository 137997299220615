import React from 'react'
import '../CSS/shop.css'





const ShipItem = ({ship, handleSelectedItem3 }) => {

    const handleBuyItem = (e) =>{
        handleSelectedItem3(e.target.value)
    }
  return (
    <div className='game-item'>

    <img src={ship.img}alt="" height="60" width="60"/> ${ship.price}
    <br/><button onClick={handleBuyItem} value={ship._id}className="load-button">Buy</button>
  
    </div>
  )
}

export default ShipItem