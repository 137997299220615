import React from 'react'
import { Link,useNavigate } from 'react-router-dom'

const Defeat = ({shipStats}) => {
    
    const shipDefeat = shipStats.map((shipStats,index)=>{
       
            return <li key={index} className='ship-defeat'> <img src={shipStats.img1} alt="" height="400" width="400"/></li>
    })
const handleHealth = ()=>{
    shipStats.map((shipStats)=>{
        if (shipStats.health === 0) {
            shipStats.health = 100;
          }
          return shipStats;
    })
}
  return (
    <div className='defeat-screen'>

       <h1 className='defeat-title'>Defeat!</h1>
        {shipDefeat}
       
       <Link to="/island-map" onClick={handleHealth}className='try-again'>Try Again...?</Link>
      
    </div>

  )
}

export default Defeat